// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1SSwISE_DfYar6ArujgEy7{width:100%;display:flex;justify-content:flex-end;flex-grow:1;gap:16px}._3wazP2xLV8RwclOKzB7M7C{padding-bottom:20px}._3S0l2SNSZyztB0vaSbWgjt{min-width:88px;margin:0 10px}._3OtB0h18Qj1jqwI1PScVyA{width:500px}._3OtB0h18Qj1jqwI1PScVyA ._3wazP2xLV8RwclOKzB7M7C{padding-bottom:4px}._3OtB0h18Qj1jqwI1PScVyA ul{padding-bottom:16px}._3OtB0h18Qj1jqwI1PScVyA li{list-style:none;font-weight:bold}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"buttonContainer": "_1SSwISE_DfYar6ArujgEy7",
	"cancelEditMessage": "_3wazP2xLV8RwclOKzB7M7C",
	"button": "_3S0l2SNSZyztB0vaSbWgjt",
	"unsavedTabsDetails": "_3OtB0h18Qj1jqwI1PScVyA"
};
module.exports = exports;
