import { Status } from "domain/reports";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const V1_API_PATH = "/api/v1/reports/";
const DELETE_REPORT_API_PATH = "/api/v1/delete-report-jobs";
export interface ReportDeletionNotificationDataDto {
    job_id: string | null;
    total_reports: string;
}

export interface ReportDeletionNotificationData {
    jobId: string | null;
    totalReports: string;
}

export interface ReportDeletionJobReportDto {
    report_uuid: string;
    failure_reason?: string;
    report_status: string;
    product_id: string;
    report_date: string;
}

export interface ReportDeletionJobReport {
    reportUuid: string;
    failureReason?: string;
    status: string;
    productId: string;
    reportDate: string;
}

export interface ReportDeletionJobResponseDto {
    cursor: string[];
    status: Status;
    report_jobs: ReportDeletionJobReportDto[];
    total_reports: number;
    total_reports_deleted: number;
    total_failures: number;
    precessed_count: number;
    job_uuid: string;
}

export interface ReportDeletionJobResponse {
    cursor: string[];
    status: Status;
    reports: ReportDeletionJobReport[];
    totalCount: number;
    totalReportsDeleted: number;
    failedCount: number;
    precessedCount: number;
    jobUuid: string;
}

class ReportDeletionService {
    public fetchReportDeletionJob(
        jobId: string,
        cursor: string[],
        abortController: AbortController
    ): Promise<ReportDeletionJobResponse> {
        const urlSearchParams = new URLSearchParams();
        if (cursor != null && cursor.length == 3) {
            cursor.forEach((cursorPart) => {
                urlSearchParams.append("cursor", cursorPart);
            });
        }
        const param = urlSearchParams.toString();
        let url = DELETE_REPORT_API_PATH + "/" + jobId;
        if (param !== "") {
            url += "?" + param;
        }
        return apiGatewayService
            .invokeApi(url, "GET", null, {
                abortSignal: abortController.signal,
                refreshSession: true,
                apiType: ApiType.LAUREL,
            })
            .then((response: ReportDeletionJobResponseDto) => Promise.resolve(toReportDeletionJobData(response)));
    }

    public deleteReport(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(V1_API_PATH + uuid, "DELETE", null, {
            abortSignal: abortController.signal,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public deleteMultipleReports(reportUuids: string[], abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(
            V1_API_PATH + "delete",
            "POST",
            {
                report_uuids: reportUuids,
            },
            {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }

    public fetchReportsUnderDeletion(abortController: AbortController): Promise<string[]> {
        return apiGatewayService
            .invokeApi(DELETE_REPORT_API_PATH, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.LAUREL,
            })
            .then((reports: string[]) => {
                return reports;
            });
    }
}

export function toReportDeletionNotificationData(
    dto: ReportDeletionNotificationDataDto
): ReportDeletionNotificationData {
    return {
        jobId: dto.job_id,
        totalReports: dto.total_reports,
    };
}

function toReportDeletionJobReport(report: ReportDeletionJobReportDto): ReportDeletionJobReport {
    return {
        reportUuid: report.report_uuid,
        status: report.report_status,
        failureReason: report.failure_reason,
        productId: report.product_id,
        reportDate: report.report_date,
    };
}

export function toReportDeletionJobData(dto: ReportDeletionJobResponseDto): ReportDeletionJobResponse {
    return {
        cursor: dto.cursor,
        status: dto.status,
        reports: dto.report_jobs.map((report) => toReportDeletionJobReport(report)),
        totalCount: dto.total_reports,
        totalReportsDeleted: dto.total_reports_deleted,
        failedCount: dto.total_failures,
        precessedCount: dto.precessed_count,
        jobUuid: dto.job_uuid,
    };
}

export const reportDeletionService = new ReportDeletionService();
