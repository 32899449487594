// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-aRoTqam4YG7HvWUkfmBe{width:534px;height:58px;background:#fafafa;border-radius:8px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:12px 16px}.U3s9Wv5d1gGrjg-VMaXzn{display:flex;flex-direction:row;align-items:center;justify-content:center;font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#3b5dab}._3VaOnvX1hFmdevOmTP85r9{width:1px;height:14px;background:#cecece}._3w6pEEXbjYQF2TQF5Hx61u{font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#303030}.n7Am7IFxgi1_6qZZMLz5a{font-style:normal;font-weight:600;font-size:10px;line-height:14px;letter-spacing:.02em;text-transform:uppercase;color:#606060}", ""]);
// Exports
exports.locals = {
	"support": "-aRoTqam4YG7HvWUkfmBe",
	"item": "U3s9Wv5d1gGrjg-VMaXzn",
	"spacer": "_3VaOnvX1hFmdevOmTP85r9",
	"needHelp": "_3w6pEEXbjYQF2TQF5Hx61u",
	"supportLabel": "n7Am7IFxgi1_6qZZMLz5a"
};
module.exports = exports;
