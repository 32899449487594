import * as endpointRepository from "services/login/endpointRepository";

const POST_HEADERS = { "Content-Type": "application/json" };
const CREDENTIALS = "include";

export enum ApiType {
    STAN = "stan",
    LAUREL = "laurel",
    ARTHUR = "arthur",
    GLOBAL_STAN = "globalStan",
    OLIVER = "oliver",
    MAINSTREAM = "mainstream",
    BUCKET = "bucket",
}

class ApiGatewayService {
    /**
     * Invokes desired API endpoint with the given method and request body.
     *
     * @param apiPath           Endpoint to invoke.
     * @param method            Method to use, e.g., "get" or "post".
     * @param body              Request body as an object.
     * @param options           Additional options including abortController or abortSignal.
     */
    public async invokeApi<K, T>(
        apiPath: string,
        method: string,
        body: K | null = null,
        {
            abortSignal = undefined,
            refreshSession = true,
            apiType = ApiType.STAN,
            headers = undefined,
            includeCredentials = true,
            setWindowLocation = false,
            emptyResponse = false,
            region = undefined,
            jsonResponse = true,
        }: {
            abortSignal?: AbortSignal;
            refreshSession?: boolean;
            apiType?: ApiType;
            headers?: HeadersInit;
            includeCredentials?: boolean;
            setWindowLocation?: boolean;
            emptyResponse?: boolean;
            region?: string;
            jsonResponse?: boolean;
        } = {}
    ): Promise<T> {
        const url = ApiGatewayService.getApiUrl(apiPath, apiType, region);

        refreshSession = refreshSession ?? true;
        method = method.toUpperCase();

        const commonRequestInit: RequestInit = {
            method,
            signal: abortSignal,
        };

        if (includeCredentials) {
            commonRequestInit.credentials = CREDENTIALS;
        }

        const requestInit =
            method === "GET" || method === "HEAD"
                ? {
                      ...commonRequestInit,
                      headers: headers ?? {},
                  }
                : {
                      ...commonRequestInit,
                      headers: {
                          ...POST_HEADERS,
                          ...(headers ?? {}),
                      },
                      body: JSON.stringify(body ?? {}),
                  };

        const response = await fetch(url, requestInit);

        if (refreshSession && [401, 403].includes(response.status)) {
            try {
                await this.invokeApi("/authentication/refresh", "get", null, {
                    abortSignal,
                    refreshSession: false,
                });
            } catch (e) {
                const path = window.location.pathname;
                if (path !== "/logout") {
                    window.location.replace("/logout");
                }
            }
            return await this.invokeApi(apiPath, method, body, {
                abortSignal,
                refreshSession: false,
                apiType,
                headers,
                includeCredentials,
            });
        }

        if (200 <= response.status && response.status < 300) {
            if (setWindowLocation) {
                window.location.href = url;
            }
            return emptyResponse ? {} : jsonResponse ? response.json() : response;
        }

        throw new Error(await response.text());
    }

    static getApiUrl(apiPath: string, apiType: ApiType = ApiType.STAN, region?: string): string {
        let url;
        if (apiType === ApiType.OLIVER) {
            url = endpointRepository.getOliverUrl();
            if (typeof region !== "undefined") {
                url = this.redirectToTenantRegion(url, region, "https://oliver-");
            }
        } else if (apiType === ApiType.MAINSTREAM) {
            url = process.env.MAINSTREAM_GLOBAL_URL;
        } else if (window.location.hostname === "localhost") {
            if (apiType === ApiType.LAUREL) {
                url = process.env.LAUREL_URL;
            } else if (apiType === ApiType.ARTHUR) {
                url = process.env.ARTHUR_URL;
            } else if (apiType == ApiType.BUCKET) {
                url = process.env.GLOBAL_URL;
            } else {
                url = process.env.STAN_URL;
            }
        } else if (apiType === ApiType.LAUREL) {
            url = endpointRepository.getLaurelUrl();
            if (typeof region !== "undefined") {
                url = this.redirectToTenantRegion(url, region, "https://laurel-");
            }
        } else if (apiType === ApiType.GLOBAL_STAN) {
            url = process.env.STAN_GLOBAL_URL;
        } else if (apiType === ApiType.STAN) {
            url = endpointRepository.getStanUrl();
            if (typeof region !== "undefined") {
                url = this.redirectToTenantRegion(url, region, "https://stan-");
            }
        } else if (apiType === ApiType.ARTHUR) {
            url = endpointRepository.getArthurUrl();
            if (region != null) {
                url = this.redirectToTenantRegion(url, region, "https://arthur-");
            }
        } else if (apiType === ApiType.BUCKET) {
            url = process.env.GLOBAL_URL;
        } else {
            throw new Error("Unknown API type: " + apiType);
        }

        return url + apiPath;
    }

    public async fetch(url: string, requestInit?: RequestInit): Promise<Response> {
        return fetch(url, requestInit);
    }

    static redirectToTenantRegion(url: string, region: string, domain: string): string {
        const contents = url.split(".");
        contents.shift();
        return domain + region + "." + contents.join(".");
    }
}

export { ApiGatewayService };
export const apiGatewayService = new ApiGatewayService();
