import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./feature-update-modal.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { DOT } from "domain/globalConstants";
import { getLanguage } from "services/language/languageRepository";
import { Profile, profileToNameMap, workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { logger } from "utils/logging";

export interface Highlight {
    title: string;
    text: string;
}

export interface HighlightData {
    profile: Profile;
    highlights: Highlight[];
}

interface Props {
    open: boolean;
    hide: () => void;
    title: string;
    showBmpHighlights: boolean;
    banner?: JSX.Element | undefined;
    actions?: JSX.Element;
    bmpHighlights?: HighlightData;
    testId: string;
}

const FeatureUpdateModal = (props: Props) => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { t } = useTranslation();
    const banner = props.banner ? props.banner : <></>;
    const errorIcon = (
        <FailedRedNotificationIcon
            backgroundColor={theme.errorIconColor}
            iconColor={theme.contentBackgroundColor}
            size={42}
        />
    );
    const [highlights, setHighlights] = React.useState<HighlightData[]>([]);
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const [loading, setLoading] = React.useState(false);
    const [fetchFailure, setFetchFailure] = React.useState(false);

    React.useEffect(() => {
        const allHighlights: HighlightData[] = [];
        if (props.showBmpHighlights && props.bmpHighlights) {
            allHighlights.push(props.bmpHighlights);
        }
        const updates = user?.workflowUpdates;
        if (!props.open || !updates) {
            setHighlights(allHighlights);
            return;
        }
        setLoading(true);
        const fetchTasks: Promise<boolean>[] = [];
        const locale = getLanguage().code;
        Object.entries(updates).forEach(([profile, version]) => {
            const task = new Promise((resolve: (result: boolean) => void) => {
                workflowService
                    .fetchWorkflowEditorHighlights(profile, version, locale)
                    .then((highlights) => {
                        allHighlights.push({ profile: profile.toLowerCase() as Profile, highlights: highlights });
                        resolve(true);
                    })
                    .catch(() => {
                        logger.error(
                            `Failed to fetch highlights for profile "${profile}" and locale "${locale}". Trying to fetch the default highlights.`
                        );
                        workflowService
                            .fetchWorkflowEditorHighlights(profile, version, "en_US")
                            .then((highlights) => {
                                allHighlights.push({
                                    profile: profile.toLowerCase() as Profile,
                                    highlights: highlights,
                                });
                                resolve(true);
                            })
                            .catch(() => {
                                setFetchFailure(true);
                                logger.error("Failed to fetch highlights.");
                                resolve(false);
                            });
                    });
            });
            fetchTasks.push(task);
        });
        Promise.allSettled(fetchTasks).then(() => {
            setHighlights(allHighlights);
            setLoading(false);
        });
    }, []);
    return (
        <Modal isOpen={props.open} hideModal={props.hide} modalTitle={props.title} maxHeight={800}>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <div data-testid={props.testId}>
                    <div className={style.banner}>{fetchFailure ? errorIcon : banner}</div>
                    {fetchFailure ? (
                        <>
                            <Heading tag="h2" alignCenter={true}>
                                {t("FeatureUpdateModal.failureHeader")}
                            </Heading>
                            <TextBlock alignCenter={true}>{t("FeatureUpdateModal.failureSubtext")}</TextBlock>
                        </>
                    ) : (
                        highlights.map((profileHighlights) => {
                            return (
                                <>
                                    <Heading tag="h1" variant="SUBTITLE_1" className={style.heading}>
                                        {profileToNameMap.get(profileHighlights.profile)}
                                    </Heading>
                                    {profileHighlights.highlights.map((highlight, index) => {
                                        return (
                                            <div key={index} className={style.improvements}>
                                                <div>{DOT}</div>
                                                <div>
                                                    <div>
                                                        <b>{highlight.title}</b>
                                                    </div>
                                                    <div>{highlight.text}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            );
                        })
                    )}
                    <TextBlock>
                        {t("FeatureUpdateModal.discoverFullList")}{" "}
                        <a href={"https://support.blancco.com/space/NEWS/11304968/News+Recent+Blogs"}>release notes</a>
                    </TextBlock>
                    <ButtonContainer>{props.actions}</ButtonContainer>
                </div>
            )}
        </Modal>
    );
};

export default FeatureUpdateModal;
