import classNames from "classnames";
import { Field, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/tenants/add-tenant/add-tenant.scss";
import Tooltip from "components/tooltip/Tooltip";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

export interface DrawbackPreventionFormValues {
    drawbackPrevention: boolean;
}

interface DrawbackPreventionData {
    formikProps: FormikProps<DrawbackPreventionFormValues>;
    miniLoader?: boolean;
    setMiniLoader?: (value: boolean) => void;
    theme: typeof defaultColor | typeof tenantColor;
}

export const DrawbackPrevention = ({ formikProps, miniLoader, theme }: DrawbackPreventionData) => {
    const { t } = useTranslation();
    const loader = miniLoader && (
        <div className={style.loaderContainer}>
            <LoadingIndicator small={true} />
        </div>
    );

    return (
        <div className={classNames(form.formFields, style.defaultValueCheckbox)}>
            {loader}
            <label htmlFor="drawbackPrevention" className={classNames(form.label)}>
                {t("EditCustomerView.preventLicenseDrawback")}
            </label>
            <label className={classNames(form.container)}>
                <Field
                    type="checkbox"
                    id="drawbackPrevention"
                    className={classNames(form.input)}
                    checked={formikProps.values.drawbackPrevention}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.drawback.drawbackPreventionCheckbox}
                    onChange={formikProps.handleChange}
                />
                <span className={form.checkmark} />
            </label>
            <Tooltip
                content={<div className={style.tooltipOpen}>{t("AddCustomerForm.licenseWithdrawalTooltip")}</div>}
                placement={"top"}
            >
                <div className={style.info}>
                    <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                </div>
            </Tooltip>
        </div>
    );
};
