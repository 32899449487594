import { ManifestWorkflowEditor } from "services/workflows/WorkflowService";

const GROUP_POSTFIX = 4;
const GROUP_POSTFIX_NUMBER = 5;
const VERSION_PATTERN = /^([0-9]+)\.([0-9]+)\.([0-9]+)([a-z]*)([0-9]*)$/i;

export const compareEditorVersionDtos = (left: ManifestWorkflowEditor, right: ManifestWorkflowEditor): number => {
    return compareEditorVersions(left.version, right.version);
};

export const compareEditorVersions = (left: string | undefined | null, right: string | undefined | null): number => {
    if (left === undefined || left === null || right === undefined || right === null) {
        return 0;
    }
    const groupsLeft = left.match(VERSION_PATTERN);
    const groupsRight = right.match(VERSION_PATTERN);
    if (groupsLeft === null) {
        // eslint-disable-next-line no-console
        console.warn("Invalid version number: " + left);
        return 0;
    }
    if (groupsRight === null) {
        // eslint-disable-next-line no-console
        console.warn("Invalid version number: " + right);
        return 0;
    }
    for (let i = 1; i < GROUP_POSTFIX; i++) {
        const left = parseInt(groupsLeft[i], 10);
        const right = parseInt(groupsRight[i], 10);
        if (left < right) {
            return 1;
        } else if (left > right) {
            return -1;
        }
    }
    const postfix = groupsLeft[GROUP_POSTFIX].localeCompare(groupsRight[GROUP_POSTFIX]);
    if (postfix === 0) {
        let left = parseInt(groupsLeft[GROUP_POSTFIX_NUMBER], 10);
        left = isNaN(left) ? 1 : left;
        let right = parseInt(groupsRight[GROUP_POSTFIX_NUMBER], 10);
        right = isNaN(right) ? 1 : right;
        if (left < right) {
            return 1;
        } else if (left > right) {
            return -1;
        }
    }
    return postfix;
};

export const deduceEditorVersion = (editorVersion: string) => {
    const lowercaseEditorVersion = editorVersion.toLowerCase();
    if (lowercaseEditorVersion.includes("dev")) {
        return lowercaseEditorVersion.replace("dev", "");
    } else if (lowercaseEditorVersion.includes("rc")) {
        return lowercaseEditorVersion.split("rc")[0];
    } else if (lowercaseEditorVersion.includes("a")) {
        return lowercaseEditorVersion.split("a")[0];
    } else {
        return lowercaseEditorVersion;
    }
};
