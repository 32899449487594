import classNames from "classnames";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { endOfDay, startOfDay, subDays } from "rsuite/esm/utils/dateUtils";

import style from "./license-events.scss";
import CustomDatePickerView from "components/common-date-picker/CustomDatePicker";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import { useOutsideClick } from "components/header/MenuPanel";
import Checkmark from "components/icons/Checkmark";
import Info from "components/icons/Info";
import Warning from "components/icons/Warning";
import { createProductIdToNameMap, createSortedLicenseTypes, isSubscriptionLicense } from "components/licenses/common";
import { createProductsMap } from "components/licenses/delivery-history/ViewDeliveryHistory";
import tokenTableStyle from "components/licenses/token-pricing-tier-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import tenantStyle from "components/tenants/tenants.scss";
import Heading from "components/typography/heading/Heading";
import { AUTH_LICENSE_POOL_VIEW } from "domain/authority";
import {
    ADD_ACTIONS,
    CONSUMPTION_ACTIONS,
    DELETE_ACTIONS,
    DELIVERY_ACTIONS,
    EVENT_ACTIONS,
    EVENT_TYPE_ADDED,
    EVENT_TYPE_CONSUMED,
    EVENT_TYPE_DELETED,
    EVENT_TYPE_DELIVERED,
    EVENT_TYPE_WITHDRAWN,
    EventAction,
    EventActions,
    EVENTS,
    EventType,
    FilterState,
    KeyCursor,
    LicenseEvent,
    LicenseEventQuery,
    LicenseEventResult,
    TableState,
    WITHDRAWN_ACTIONS,
} from "domain/licenseEvent";
import { licenseEventsService } from "services/licenses/LicenseEventService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantHasFeatureLicense } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { isAddOn } from "utils/commonFunctions";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const ICON_SIZE = 12;
const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

interface LicenseEventsTableProps {
    filterState: FilterState;
    setFilterState: React.Dispatch<React.SetStateAction<FilterState>>;
    exportFailed: boolean;
    setExportFailed: (value: boolean) => void;
    filtersMissing: boolean;
    setFiltersMissing: (value: boolean) => void;
    tableState: TableState;
    setTableState: (value: TableState) => void;
    fetchCount: number;
    refreshCount: number;
    setRefreshCount: (value: number) => void;
    setExportLoading: (value: boolean) => void;
    exportModalVisible: boolean;
    setExportModalVisible: (value: boolean) => void;
    setStartExport: (value: boolean) => void;
    setEventCount: (value: number) => void;
}

const createConsumptionInfoCell = (value: string | undefined): JSX.Element => {
    return value ? (
        <div className={style.alignment}>
            <TextWithTooltip text={value} />
            <CopyToClipboard value={value} />
        </div>
    ) : (
        <></>
    );
};

const createAssetInfoCell = (value: string | undefined, t: TFunction): JSX.Element => {
    return value ? (
        <div className={style.alignment}>
            <TextWithTooltip text={t("LicenseEvent.table.entitlementId", { assetId: value })} />
            <CopyToClipboard value={value} />
        </div>
    ) : (
        <></>
    );
};

const createTargetedTenantInfoCell = (value: string | undefined): JSX.Element => {
    return value ? (
        <div className={style.alignment}>
            <TextWithTooltip text={value} />
            <CopyToClipboard value={value} />
        </div>
    ) : (
        <></>
    );
};

const connector = connect(mapState, { applyMode });
type Props = ConnectedProps<typeof connector> & LicenseEventsTableProps;

const LicenseEventsTable: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    let refresh = false;
    const defaultDateRange: DateRange = [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())];
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [requestFailure, setRequestFailure] = React.useState();

    const ALL_PRODUCTS = createProductIdToNameMap();
    const ref = useOutsideClick(() => {
        setProductsFilterVisible(false);
        setEventTypeFilterVisible(false);
        setActionFilterVisible(false);
    });
    const productIdList = createSortedLicenseTypes().map((each) => each.productId);
    const [productsFilterVisible, setProductsFilterVisible] = React.useState(false);
    const [eventTypeFilterVisible, setEventTypeFilterVisible] = React.useState(false);
    const [actionsFilterVisible, setActionFilterVisible] = React.useState(false);
    const [valuesChanged, setValuesChanged] = React.useState(false);
    const [selectActionsOnEventChange, setSelectActionsOnEventChange] = React.useState(false);
    const [actionsList, setActionsList] = React.useState<string[]>(props.filterState.actions);
    const [totalCount, setTotalCount] = React.useState<number | undefined>(undefined);
    const [accurateCount, setAccurateCount] = React.useState<boolean | undefined>(undefined);
    const [countLoaded, setCountLoaded] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const closeDropdown = () => {
        setProductsFilterVisible(false);
        setEventTypeFilterVisible(false);
        setActionFilterVisible(false);
    };
    const searchQuery: LicenseEventQuery = {
        search: props.filterState.quickSearchFilter,
        actions: props.filterState.eventTypes,
        productIds: props.filterState.productIds,
        eventActions: props.filterState.actions,
        from: props.filterState.startDate,
        to: props.filterState.endDate,
        cursor: props.tableState.cursor,
    };

    const fetchTotalCount = () => {
        props.setExportLoading(true);
        setCountLoaded(false);
        const countController = new AbortController();
        abortControllers.push(countController);
        licenseEventsService
            .fetchEventsCount(searchQuery, countController)
            .then((count) => {
                setTotalCount(count.totalEvents);
                setAccurateCount(count.accurate);
                setCountLoaded(true);
                props.setEventCount(count.totalEvents);
            })
            .finally(() => props.setExportLoading(false))
            .catch(() => {
                if (!countController.signal.aborted) {
                    setTotalCount(0);
                }
            });
    };

    function clearTableState() {
        props.setTableState({
            licenseEvents: [],
            cursor: undefined,
            scrollPosition: 0,
        });
    }

    function closeAndFetch() {
        closeDropdown();
        if (valuesChanged) {
            fetchDataOrClearTable();
        }
    }

    const updateDateRange = (startDate?: string, endDate?: string) => {
        if (props.filterState.startDate === startDate && props.filterState.endDate === endDate) {
            return;
        }
        props.setFilterState((previous) =>
            Object.assign({}, previous, {
                cursor: [],
                startDate: startDate,
                endDate: endDate,
            })
        );
    };

    const extractCursorValue = (): KeyCursor | undefined => {
        if (valuesChanged || refresh) {
            return undefined;
        }
        return props.tableState.cursor;
    };

    const handleLicenseEvents = (licenseEventsFromApi: LicenseEvent[]): LicenseEvent[] => {
        if (valuesChanged || refresh) {
            return licenseEventsFromApi;
        }
        return props.tableState.licenseEvents.concat(licenseEventsFromApi);
    };

    const handleScrollState = () => {
        if (valuesChanged || refresh) {
            return 0;
        }
        return props.tableState.licenseEvents.length - 1;
    };

    const fetchData = () => {
        setLoading(true);
        setInitialLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const searchQuery: LicenseEventQuery = {
            search: props.filterState.quickSearchFilter,
            actions: props.filterState.eventTypes,
            productIds: props.filterState.productIds,
            eventActions: props.filterState.actions,
            from: props.filterState.startDate,
            to: props.filterState.endDate,
            cursor: extractCursorValue(),
        };

        setRequestFailure(undefined);
        licenseEventsService
            .fetchLicenseEvents(searchQuery, abortController)
            .then((data: LicenseEventResult) => {
                if (data.licenseEvents.length < 1 && typeof data.cursor != "undefined") {
                    setRequestFailure(t("LicenseEvent.table.timeoutMessage"));
                } else {
                    props.setTableState({
                        ...props.tableState,
                        licenseEvents: handleLicenseEvents(data.licenseEvents),
                        scrollPosition: handleScrollState(),
                        cursor: data.cursor,
                    });
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailure(t("LicenseEvent.table.requestFailure"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                    setLoading(false);
                    setValuesChanged(false);
                }
            });
    };

    const deduceLicenseChange = (type: string, amount: number) => {
        if (type.startsWith("feature")) {
            return t("Licenses.na");
        }

        return amount;
    };

    const generateLicenseTooltipContent = (row: LicenseEvent) => {
        const all_products = createProductsMap();

        const usedAmount = Math.abs(row.usedAmount ?? 0);
        const productIdValue = all_products.get(row.productId) ?? "";

        const amountBefore = row.amountBefore ?? 0;
        const amountAfter = row.amountAfter ?? 0;
        const assignedAmount = amountBefore - amountAfter;
        const tooltipText = t("LicenseEvent.table.entitlementCreationChangeToolTip", {
            usedAmount,
            productIdValue,
            assignedAmount,
        });
        if (assignedAmount == usedAmount) {
            return null;
        }

        return (
            <TextWithTooltip text={tooltipText}>
                <div className={tokenTableStyle.infoText}>
                    <Info borderColor={theme.contentBackgroundColor} color={theme.textColor} />
                </div>
            </TextWithTooltip>
        );
    };

    const deduceEventTypeTranslation = (eventType: EventType) => {
        switch (eventType) {
            case EVENT_TYPE_CONSUMED: {
                return t("LicenseEvent.table.eventType.consumed");
            }
            case EVENT_TYPE_DELIVERED: {
                return t("LicenseEvent.table.eventType.delivered");
            }
            case EVENT_TYPE_WITHDRAWN: {
                return t("LicenseEvent.table.eventType.withdrawn");
            }
            case EVENT_TYPE_ADDED: {
                return t("LicenseEvent.table.eventType.added");
            }
            case EVENT_TYPE_DELETED: {
                return t("LicenseEvent.table.eventType.deleted");
            }
            default: {
                throw Error("Unknown event type: " + eventType);
            }
        }
    };

    const deduceEventAction = (eventAction: EventAction) => {
        switch (eventAction) {
            case EventActions.LICENSE_CONSUMPTION:
                return t("LicenseEvent.table.eventAction.licenseConsumption");
            case EventActions.SECURE_LICENSE_CONSUMPTION:
                return t("LicenseEvent.table.eventAction.secureLicenseConsumption");
            case EventActions.FEATURE_LICENSE_CONSUMPTION:
                return t("LicenseEvent.table.eventAction.featureLicenseConsumption");
            case EventActions.LICENSE_KEY_CONSUMPTION:
                return t("LicenseEvent.table.eventAction.licenseKeyConsumption");
            case EventActions.LICENSE_DELIVERY:
                return t("LicenseEvent.table.eventAction.licenseDelivery");
            case EventActions.ENTITLEMENT_CREATION:
                return t("LicenseEvent.table.eventAction.entitlementCreation");
            case EventActions.LICENSE_KEY_CREATION:
                return t("LicenseEvent.table.eventAction.licenseKeyCreation");
            case EventActions.TENANT_DELETION:
                return t("LicenseEvent.table.eventAction.tenantDeletion");
            case EventActions.LICENSE_POOL_UPDATE:
                return t("LicenseEvent.table.eventAction.licensePoolUpdate");
            case EventActions.LICENSING_MODEL_CHANGE:
                return t("LicenseEvent.table.eventAction.licensingModelChange");
            case EventActions.LICENSE_DELIVERY_ASSIGNED:
                return t("LicenseEvent.table.eventAction.licenseDeliveryAssigned");
            case EventActions.LICENSE_DELIVERY_RECEIVED:
                return t("LicenseEvent.table.eventAction.licenseDeliveryReceived");
        }
    };

    const clearTable = () => {
        setInitialLoading(false);
        setLoading(false);
        setRequestFailure(undefined);
    };

    const columns: Array<Column<LicenseEvent>> = [
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventDate")} key={"1"} />,
            accessor: "eventDate",
            Cell: ({ cell: { value } }) => <DateCell value={value} tooltip={true} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.product")} key={"2"} />,
            accessor: "productId",
            Cell: ({ row }) => <TextWithTooltip text={ALL_PRODUCTS.get(row.original.productId)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventType.column")} key={"3"} />,
            accessor: "eventType",
            Cell: ({ row }) => <TextWithTooltip text={deduceEventTypeTranslation(row.original.eventType)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventAction.column")} key={"4"} />,
            accessor: "eventAction",
            Cell: ({ row }) => <TextWithTooltip text={deduceEventAction(row.original.eventAction)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.change")} key={"5"} />,
            accessor: "change",
            Cell: ({ row }) => {
                const tooltipContent =
                    isSubscriptionLicense(row.original.productId) &&
                    row.original.eventAction == EventActions.LICENSE_CONSUMPTION;
                return row.original.eventAction === EventActions.ENTITLEMENT_CREATION &&
                    !isAddOn(row.original.productId) ? (
                    <div className={style.info}>
                        <TextWithTooltip text={deduceLicenseChange(row.original.productId, row.original.change)} />
                        {generateLicenseTooltipContent(row.original)}
                    </div>
                ) : (
                    <div className={style.info}>
                        <TextWithTooltip
                            text={`${deduceLicenseChange(row.original.productId, row.original.change)} ${
                                tooltipContent
                                    ? t("LicenseEvent.table.usedAmountSubscriptionToolTip", {
                                          usedAmount: row.original.usedAmount?.toString(),
                                      })
                                    : ""
                            }`}
                        />
                    </div>
                );
            },
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.remaining")} key={"6"} />,
            accessor: "remaining",
            Cell: ({ row }) => <TextWithTooltip text={row.original.remaining} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.expirationDate")} key={"7"} />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) => {
                return value ? <DateCell value={value} tooltip={true} withoutTime={true} /> : <></>;
            },
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.user")} key={"8"} />,
            accessor: "user",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.notes")} key={"9"} />,
            accessor: "notes",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.licenseConsumptionId")} key={"10"} />,
            accessor: "licenseConsumptionId",
            Cell: ({ cell: { value } }) => createConsumptionInfoCell(value),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.assetId")} key={"11"} />,
            accessor: "assetId",
            Cell: ({ cell: { value } }) => createAssetInfoCell(value, t),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.targetedTenant")} key={"13"} />,
            accessor: "targetedTenant",
            Cell: ({ cell: { value } }) => createTargetedTenantInfoCell(value),
        },
    ];
    tenantHasFeatureLicense("FEATURE_LICENSE_POOLS") &&
        userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_VIEW]) &&
        columns.push({
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.licensePool")} key={"12"} />,
            accessor: "poolName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });

    React.useEffect(() => {
        clearTableState();
        props.setRefreshCount(props.refreshCount + 1);
    }, [props.filterState.quickSearchFilter, props.filterState.startDate, props.filterState.endDate]);
    React.useEffect(() => {
        refresh = true;
        if (hasValuesInDropDown()) {
            fetchData();
        } else {
            clearTable();
        }
    }, [props.refreshCount]);

    React.useEffect(() => {
        if (hasValuesInDropDown()) {
            fetchTotalCount();
        }
    }, [props.refreshCount]);

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    React.useEffect(() => {
        selectActionsBasedOnEventTypes();
    }, [props.filterState.eventTypes]);

    React.useEffect(() => {
        selectEventTypesBasedOnActions();
    }, [actionsList]);

    React.useEffect(() => {
        closeAndFetch();
    }, [props.fetchCount]);

    const handleSearchQueryChange = (search: string) => {
        handleFilterChanges({ quickSearchFilter: search });
    };

    const handleProductIdsChange = (productIds: string[]) => {
        handleFilterChanges({ productIds: productIds });
    };

    const handleActionChange = (eventActions: string[]) => {
        handleFilterChanges({ actions: eventActions });
    };

    const handleEventTypesChange = (eventTypes: EventType[]) => {
        handleFilterChanges({ eventTypes: eventTypes });
    };

    const handleFilterChanges = (update: Partial<FilterState>) => {
        props.setFilterState((prevState) => ({
            ...prevState,
            ...update,
        }));
    };

    const hasValuesInDropDown = (): boolean => {
        return (
            props.filterState.productIds.length > 0 &&
            props.filterState.eventTypes.length > 0 &&
            props.filterState.actions.length > 0
        );
    };

    const fetchDataOrClearTable = () => {
        if (hasValuesInDropDown()) {
            fetchData();
            fetchTotalCount();
        } else {
            clearTableState();
        }
    };

    function showCheckmarkIcon() {
        return (
            <div className={style.headerCheckbox}>
                <Checkmark color={props.theme.iconFillColor} size={ICON_SIZE} strokeWidth={1} />
            </div>
        );
    }

    const handleProductClick = (product: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            handleProductIdsChange(props.filterState.productIds.concat(product));
        } else {
            handleProductIdsChange(props.filterState.productIds.filter((each) => each !== product));
        }
        setValuesChanged(true);
    };

    const handleEventTypesClick = (eventType: EventType, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            handleEventTypesChange(props.filterState.eventTypes.concat(eventType));
        } else {
            handleEventTypesChange(props.filterState.eventTypes.filter((each) => each !== eventType));
        }
        setSelectActionsOnEventChange(true);
        setValuesChanged(true);
    };

    const handleEventActionsClick = (eventAction: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setActionsList(actionsList.concat(eventAction));
            handleActionChange(props.filterState.actions.concat(eventAction));
        } else {
            setActionsList(actionsList.filter((each) => each !== eventAction));
            handleActionChange(props.filterState.actions.filter((each) => each !== eventAction));
        }
        setValuesChanged(true);
        setSelectActionsOnEventChange(false);
    };

    const selectActionsBasedOnEventTypes = () => {
        if (selectActionsOnEventChange) {
            const actions: string[] = [];
            props.filterState.eventTypes.map((eventType) => {
                if (eventType === EVENT_TYPE_DELIVERED) {
                    actions.push(...DELIVERY_ACTIONS);
                } else if (eventType === EVENT_TYPE_WITHDRAWN) {
                    actions.push(...WITHDRAWN_ACTIONS);
                } else if (eventType === EVENT_TYPE_CONSUMED) {
                    actions.push(...CONSUMPTION_ACTIONS);
                } else if (eventType === EVENT_TYPE_ADDED) {
                    actions.push(...ADD_ACTIONS);
                } else if (eventType === EVENT_TYPE_DELETED) {
                    actions.push(...DELETE_ACTIONS);
                }
            });
            handleActionChange([...new Set(actions)]);
        }
    };

    const selectEventTypesBasedOnActions = () => {
        const eventTypes: string[] = [];
        props.filterState.actions.map((action) => {
            if (DELIVERY_ACTIONS.includes(action) && !eventTypes.includes(EVENT_TYPE_DELIVERED)) {
                eventTypes.push(EVENT_TYPE_DELIVERED);
            }
            if (WITHDRAWN_ACTIONS.includes(action) && !eventTypes.includes(EVENT_TYPE_WITHDRAWN)) {
                eventTypes.push(EVENT_TYPE_WITHDRAWN);
            }
            if (CONSUMPTION_ACTIONS.includes(action) && !eventTypes.includes(EVENT_TYPE_CONSUMED)) {
                eventTypes.push(EVENT_TYPE_CONSUMED);
            }
            if (ADD_ACTIONS.includes(action) && !eventTypes.includes(EVENT_TYPE_ADDED)) {
                eventTypes.push(EVENT_TYPE_ADDED);
                eventTypes.push(EVENT_TYPE_WITHDRAWN);
            }
            if (DELETE_ACTIONS.includes(action) && !eventTypes.includes(EVENT_TYPE_DELETED)) {
                eventTypes.push(EVENT_TYPE_DELETED);
            }
        });
        handleEventTypesChange([...new Set(eventTypes)]);
    };

    const createResultsSummarySection = () => {
        return (
            !loading && (
                <div
                    data-testid={testIds.common.primaryView.table.resultSummaryContainer}
                    onClick={() => closeDropdown}
                >
                    {props.tableState.licenseEvents.length < 1
                        ? t("Common.noSearchResultHint")
                        : typeof accurateCount === "undefined" || !countLoaded
                        ? t("Common.defaultSearchResultHint", { dataCount: props.tableState.licenseEvents.length })
                        : accurateCount
                        ? t("Common.defaultSearchResultHintWithTotal", {
                              dataCount: props.tableState.licenseEvents.length,
                              total: totalCount,
                          })
                        : t("Common.searchResultCountNotAccurate", {
                              dataCount: props.tableState.licenseEvents.length,
                              total: totalCount,
                          })}
                </div>
            )
        );
    };

    const createProductsFilter = () => {
        return (
            <>
                <div
                    className={classNames(form.select, style.selectSearch, style.ellipsis, style.commonHeight)}
                    onClick={() => {
                        setProductsFilterVisible(!productsFilterVisible);
                        setActionFilterVisible(false);
                        setActionFilterVisible(false);
                        setEventTypeFilterVisible(false);
                        productsFilterVisible &&
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_EVENTS,
                                action: Action.FILTER_BY_PRODUCT,
                            });
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.productsFilterSelect}
                >
                    {props.filterState.productIds.length === ALL_PRODUCTS.size
                        ? t("LicenseEvent.filter.product.all")
                        : props.filterState.productIds.length === 1
                        ? ALL_PRODUCTS.get(props.filterState.productIds[0])
                        : props.filterState.productIds.length <= 0
                        ? t("LicenseEvent.filter.product.none")
                        : t("LicenseEvent.filter.product.amount", { amount: props.filterState.productIds.length })}
                </div>

                <ul hidden={!productsFilterVisible} className={classNames(style.filters)}>
                    <div className={style.header}>
                        <div
                            className={style.headerText}
                            onClick={() => {
                                props.filterState.productIds.length === ALL_PRODUCTS.size
                                    ? handleProductIdsChange([])
                                    : handleProductIdsChange(productIdList);
                                setValuesChanged(true);
                            }}
                        >
                            {t("LicenseEvent.filter.product.selectAll")}
                        </div>
                        {props.filterState.productIds.length === ALL_PRODUCTS.size && showCheckmarkIcon()}
                    </div>
                    {Array.from(productIdList).map((product) => {
                        return (
                            <li key={product}>
                                <label className={style.filteringItem}>
                                    <input
                                        className={form.input}
                                        name="productIds"
                                        id={ALL_PRODUCTS.get(product)}
                                        value={ALL_PRODUCTS.get(product)}
                                        type="checkbox"
                                        onChange={(e) => handleProductClick(product, e)}
                                        checked={props.filterState.productIds.includes(product)}
                                    />
                                    <span className={style.labelSpacing}>{ALL_PRODUCTS.get(product)}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    const createActionFilter = () => {
        const actionsList = EVENT_ACTIONS;
        return (
            <>
                <div
                    className={classNames(form.select, style.selectSearch, style.ellipsis, style.commonHeight)}
                    onClick={() => {
                        setActionFilterVisible(!actionsFilterVisible);
                        setProductsFilterVisible(false);
                        setEventTypeFilterVisible(false);
                        actionsFilterVisible &&
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_EVENTS,
                                action: Action.FILTER_BY_ACTION,
                            });
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.productsFilterSelect}
                >
                    {props.filterState.actions.length === actionsList.length
                        ? t("LicenseEvent.filter.eventActions.all")
                        : props.filterState.actions.length === 1
                        ? deduceEventAction(props.filterState.actions[0])
                        : props.filterState.actions.length <= 0
                        ? t("LicenseEvent.filter.eventActions.none")
                        : t("LicenseEvent.filter.eventActions.amount", { amount: props.filterState.actions.length })}
                </div>

                <ul hidden={!actionsFilterVisible} className={style.filters}>
                    <div className={style.header}>
                        <div
                            className={style.headerText}
                            onClick={() => {
                                props.filterState.actions.length === actionsList.length
                                    ? setEventActions([])
                                    : setEventActions(actionsList);
                                setValuesChanged(true);
                            }}
                        >
                            {t("LicenseEvent.filter.eventActions.selectAll")}
                        </div>
                        {props.filterState.actions.length === actionsList.length && showCheckmarkIcon()}
                    </div>
                    {Array.from(actionsList).map((eventAction) => {
                        return (
                            <li key={eventAction}>
                                <label className={style.filteringItem}>
                                    <input
                                        className={form.input}
                                        name="eventActions"
                                        id={deduceEventAction(eventAction)}
                                        value={deduceEventAction(eventAction)}
                                        type="checkbox"
                                        onChange={(e) => handleEventActionsClick(eventAction, e)}
                                        checked={props.filterState.actions.includes(eventAction)}
                                    />
                                    <span className={style.labelSpacing}>{deduceEventAction(eventAction)}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    function setEventActions(eventActions: string[]) {
        handleActionChange(eventActions);
        setActionsList(eventActions);
    }

    const createEventTypeFilter = () => {
        const eventTypes = EVENTS;
        return (
            <>
                <div
                    className={classNames(form.select, style.selectSearch, style.ellipsis, style.commonHeight)}
                    onClick={() => {
                        setEventTypeFilterVisible(!eventTypeFilterVisible);
                        setProductsFilterVisible(false);
                        setActionFilterVisible(false);
                        eventTypeFilterVisible &&
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_EVENTS,
                                action: Action.FILTER_BY_TYPE,
                            });
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.actionsFilterSelect}
                >
                    {props.filterState.eventTypes.length === eventTypes.length
                        ? t("LicenseEvent.filter.action.all")
                        : props.filterState.eventTypes.length <= 0
                        ? t("LicenseEvent.filter.action.none")
                        : props.filterState.eventTypes.length === 1
                        ? deduceEventTypeTranslation(props.filterState.eventTypes[0])
                        : t("LicenseEvent.filter.action.amount", { amount: props.filterState.eventTypes.length })}
                </div>
                <ul hidden={!eventTypeFilterVisible} className={style.filters}>
                    <div className={style.header}>
                        <div
                            className={style.headerText}
                            onClick={() => {
                                props.filterState.eventTypes.length === eventTypes.length
                                    ? handleEventTypesChange([])
                                    : handleEventTypesChange(eventTypes);
                                setValuesChanged(true);
                                setSelectActionsOnEventChange(true);
                            }}
                        >
                            {t("LicenseEvent.filter.action.selectAll")}
                        </div>
                        {props.filterState.eventTypes.length === eventTypes.length && showCheckmarkIcon()}
                    </div>
                    {eventTypes.map((action: EventType) => {
                        return (
                            <li key={action}>
                                <label className={style.filteringItem}>
                                    <input
                                        className={form.input}
                                        name="eventTypes"
                                        id={deduceEventTypeTranslation(action)}
                                        value={deduceEventTypeTranslation(action)}
                                        type="checkbox"
                                        onChange={(e) => handleEventTypesClick(action, e)}
                                        checked={props.filterState.eventTypes.includes(action)}
                                    />
                                    <span className={style.labelSpacing}>{deduceEventTypeTranslation(action)} </span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    return (
        <>
            <div className={style.tableWrapper} ref={ref} onClick={() => closeDropdown}>
                <div className={classNames(style.count, style.paddingBottom)}>
                    <div>{createResultsSummarySection()}</div>
                    <div className={style.searchFilters}>
                        <div className={form.search}>{createProductsFilter()}</div>
                        <div className={form.search}>{createEventTypeFilter()}</div>
                        <div className={form.search}>{createActionFilter()}</div>
                        <div ref={ref} onClick={() => closeDropdown}>
                            <CustomDatePickerView
                                defaultDateRange={defaultDateRange}
                                category={Category.LICENSE_EVENTS}
                                editable={true}
                                defaultPlaceholder={!!props.filterState.endDate}
                                onUpdate={updateDateRange}
                            />
                        </div>
                        <div className={form.search} ref={ref} onClick={() => closeDropdown}>
                            <SearchView
                                setSearch={handleSearchQueryChange}
                                searchInProgress={false}
                                tooltip={t("LicenseEvent.search.placeholder")}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={layoutStyle.tableWrapper}
                    onClick={() => {
                        closeAndFetch();
                    }}
                >
                    <Table
                        tableIdentity={RepositoryKey.LICENSE_EVENTS_TABLE}
                        data={props.tableState.licenseEvents}
                        columns={columns}
                        loaded={!initialLoading}
                        failureMessage={requestFailure}
                        tooltips={true}
                        scrollTo={props.tableState.scrollPosition}
                        emptyMessage={t("LicenseEvent.table.emptyStateMessage")}
                        testId={testIds.workArea.license.licenseEvents.table.itself}
                    />
                </div>

                <div>
                    {props.tableState.cursor != null &&
                        props.tableState.licenseEvents.length != 0 &&
                        !requestFailure &&
                        (loading ? (
                            <LoadingIndicator small={true} />
                        ) : (
                            <button
                                className={buttonStyle.loadMoreButtonWithoutIcon}
                                onClick={() => {
                                    fetchData();
                                    usageStatisticsService.sendEvent({
                                        action: Action.LOAD_MORE,
                                        category: Category.LICENSE_EVENTS,
                                    });
                                }}
                                data-testid={testIds.common.primaryView.table.loadMoreButton}
                            >
                                {t("Common.loadMore")}
                            </button>
                        ))}
                </div>
            </div>
            {props.exportModalVisible ? (
                <Modal
                    isOpen={props.exportModalVisible}
                    hideModal={() => {
                        props.setExportModalVisible(false);
                    }}
                >
                    {
                        <>
                            <div className={style.warningContainer}>
                                <Warning color={props.theme.secondaryWarningBackgroundColor} />
                            </div>
                            <div className={tenantStyle.subTenant}>
                                <Heading tag={"h2"}>{t("LicenseEvent.export.exceeded")}</Heading>
                            </div>
                            <div className={style.warningContainer}>{t("LicenseEvent.export.exceededMessage")}</div>
                            <div className={style.warningContainer}>
                                <button
                                    className={buttonStyle.primaryOkButton}
                                    onClick={() => {
                                        props.setExportModalVisible(false);
                                        props.setStartExport(true);
                                    }}
                                    data-testid={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.ok")}
                                </button>
                            </div>
                        </>
                    }
                </Modal>
            ) : null}
            {props.exportFailed ? (
                <Modal
                    isOpen={props.exportFailed}
                    hideModal={() => {
                        props.setExportFailed(false);
                        props.setFiltersMissing(false);
                    }}
                    modalTitle={t("LicenseEvent.export.tooltip")}
                >
                    <div className={form.resultContainer}>
                        {props.filtersMissing
                            ? t("LicenseEvent.export.filtersMissingErrorMessage")
                            : t("Common.errorHasOccurredMessage")}
                    </div>
                    <div className={form.okButtonContainer}>
                        <button
                            className={buttonStyle.primaryOkButton}
                            onClick={() => {
                                props.setExportFailed(false);
                                props.setFiltersMissing(false);
                            }}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            ) : null}
        </>
    );
};

export default connector(LicenseEventsTable);
