import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import style from "components/users/users.scss";
import { UserTableData } from "domain/users";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userService } from "services/user/users/UserService";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    usersData: UserTableData[];
    cursor: string;
    scrollPosition?: number;
}

export interface Props {
    scrollPosition?: number;
    search?: string;
    groupUuid: string;
}

function removeDuplicateUsers(previousUserData: UserTableData[], currentUserData: UserTableData[]): UserTableData[] {
    const previousEmailIds = new Set(previousUserData.map(({ email }) => email));
    return [...previousUserData, ...currentUserData.filter(({ email }) => !previousEmailIds.has(email))];
}

const GroupUsersTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        usersData: [],
        cursor: "",
        scrollPosition: 0,
    });

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const [searchGroup] = React.useState(props.groupUuid);

    const fetchData = (abortController: AbortController, initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);

        userService
            .fetchUsers(abortController, search, initialLoading ? "" : tableState.cursor, searchGroup)
            .then((usersData) => {
                setTableState((previousState) => ({
                    scrollPosition: previousState.usersData.length - 1,
                    usersData: removeDuplicateUsers(previousState.usersData, usersData.userTableData),
                    cursor: usersData.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("UsersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const columns: Array<Column<UserTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.name")} key="name" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <div className={style.userNameCell}>
                    <TextWithTooltip text={cellInfo.value} />
                </div>
            ),
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.userStatus.title")} key="status" />,
            accessor: "enabled",
            Cell: ({ cell: { value } }) => (
                <StatusBadge
                    values={[
                        value
                            ? {
                                  status: Status.SUCCESS,
                                  title: t("UsersTable.userStatus.confirmed"),
                              }
                            : {
                                  status: Status.ERROR,
                                  title: t("UsersTable.userStatus.forceChangePassword"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.expirationDate")} key="expirationDate" />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) =>
                value ? <DateCell tooltip={true} value={value} withoutTime={true} /> : t("Common.never"),
            width: deriveColumnWidth(25, tableContainerRef),
        },
    ];
    React.useEffect(() => {
        const abortController = new AbortController();
        setTableState({ usersData: [], cursor: "", scrollPosition: 0 });
        fetchData(abortController, true);

        return () => {
            abortController.abort();
        };
    }, [search]);

    let dataCount = null;
    if (tableState.usersData.length > 0) {
        dataCount = t("UsersTable.searchResultHint", { dataCount: tableState.usersData.length });
    }
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={style.filter}>
                    <div className={formStyle.search}>
                        <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                    </div>
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.GROUP_USERS_TABLE}
                    data={tableState.usersData}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={t("UserGroups.emptyStateMessage")}
                    testId={testIds.workArea.userGroup.userGroupDetailsDialog.table.itself}
                    noAction={true}
                />
            </div>
            {tableState.cursor != null &&
                tableState.usersData.length >= 100 &&
                tableState.usersData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttonStyle.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            const abortController = new AbortController();
                            fetchData(abortController, false);
                            usageStatisticsService.sendEvent({
                                label: Label.USER_GROUP_DETAILS_DIALOG,
                                action: Action.LOAD_MORE,
                                category: Category.USER_GROUP,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

export default GroupUsersTable;
