import * as React from "react";
import { useTranslation } from "react-i18next";

import CurrentPlan from "./currentplan/CurrentPlan";
import style from "./home.scss";
import ProductCard from "./productcard/ProductCard";
import Support from "./support/Support";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const Home = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <main>
                <section className={style.planAndSupport}>
                    <CurrentPlan />
                    <Support />
                </section>
                <section className={style.products}>
                    <h2>Your products</h2>
                    <section className={style.assets}>
                        <h3>Asset life cycle</h3>
                        <p>
                            Certified erasure of all asset types: laptops, desktops, mobile, tablets, removable media,
                            server, storage and loose drives
                        </p>
                        <div className={style.productCardsWarapper}>
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard />
                        </div>
                    </section>
                    <section className={style.information}></section>
                </section>
                <section className={style.additionalTools}></section>
            </main>

            <hr></hr>
            <div>
                <div>
                    <div>Blancco Driver Eraser</div>
                    <button
                        className={buttons.primaryButtonWithoutIcon}
                        data-testid={testIds.homePage.getStarted.button}
                        onClick={() => {
                            /* TODO: Implement click handler to show get started BDE component */
                        }}
                    >
                        {t("Onboarding.home.getStartedButton")}
                    </button>
                </div>
                <hr></hr>
                <div>
                    <div>Blancco Mobile Diagnostics and Erasure</div>
                </div>
                <button
                    className={buttons.primaryButtonWithoutIcon}
                    data-testid={testIds.homePage.getStarted.button}
                    onClick={() => {
                        /* TODO: Implement click handler to show get started BMDE component */
                    }}
                >
                    {t("Onboarding.home.getStartedButton")}
                </button>
            </div>
        </>
    );
};

Home.defaultProps = {
    cols: { lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 },
    margin: [40, 40],
    containerPadding: [0, 0],
    isBounded: true,
    rowHeight: 1,
    isResizable: true,
};

export default Home;
