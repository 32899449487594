import classNames from "classnames";
import { ErrorMessage, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import loginStyle from "./login.scss";
import { LoginDetails } from "components/login/LoginView";
import { EMAIL_MAX_LENGTH } from "domain/globalConstants";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    submitHandler: (values: FormValues) => void;
    loginErrorMessage: string | null;
    ssoConfigurationCheckHandler: (values: FormValues["email"]) => Promise<LoginDetails | undefined>;
}

export interface FormValues {
    email: string;
    password: string;
}

export function hideSamlLoginSection(loginDetails?: LoginDetails): boolean {
    if (typeof loginDetails === "undefined") {
        return true;
    }
    switch (loginDetails.loginMethod) {
        case "EMAIL":
            return true;
        case "COMPANY_ID":
        case "COMPANY_ID_AND_EMAIL":
            return false;
    }
    return typeof loginDetails.loginUrl === "undefined";
}

export function hidePasswordLoginSection(loginDetails?: LoginDetails): boolean {
    if (typeof loginDetails === "undefined") {
        return false;
    }
    switch (loginDetails.loginMethod) {
        case "COMPANY_ID":
            return true;
        case "EMAIL":
        case "COMPANY_ID_AND_EMAIL":
            return false;
    }
    return false;
}

export default function LoginForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [passwordVisible, togglePasswordVisibility] = useState<boolean>(false);
    const [samlLoginSectionHidden, toggleSamlLoginSectionHidden] = useState<boolean>(true);
    const [samlLoginLink, insertSamlLoginLink] = useState<string>("");
    const [passwordLoginSectionHidden, setPasswordLoginSectionHidden] = useState<boolean>(false);
    const handleShowSamlSection = (email: string) => {
        if (email !== "") {
            const response = props.ssoConfigurationCheckHandler(email);
            if (response !== undefined) {
                response.then(function (loginDetails) {
                    if (typeof loginDetails === "undefined") {
                        insertSamlLoginLink("");
                    } else {
                        insertSamlLoginLink(loginDetails.loginUrl);
                    }
                    toggleSamlLoginSectionHidden(hideSamlLoginSection(loginDetails));
                    setPasswordLoginSectionHidden(hidePasswordLoginSection(loginDetails));
                });
            }
        }
    };
    let timeout: NodeJS.Timeout;
    const handleInvalid = (e: React.SyntheticEvent) => e.preventDefault();
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => props.submitHandler(values)}
            validationSchema={object().shape({
                email: string()
                    .required(t("LoginForm.emptyEmail"))
                    .email(t("LoginForm.invalidEmail"))
                    .max(EMAIL_MAX_LENGTH),
                password: string().required(t("LoginForm.emptyPassword")),
            })}
        >
            {(formProps) => {
                const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, touched } = formProps;
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={loginStyle.formFields}>
                            <div className={classNames(loginStyle.field)}>
                                <input
                                    autoFocus
                                    id="email"
                                    data-testid={testIds.login.form.usernameInput.itself}
                                    type="text"
                                    className={classNames(loginStyle.input, {
                                        [loginStyle.inputEmailError]: errors.email,
                                    })}
                                    value={values.email}
                                    onChange={handleChange}
                                    onKeyUp={() => {
                                        clearTimeout(timeout);
                                        timeout = setTimeout(handleShowSamlSection, 700, values.email);
                                    }}
                                    onBlurCapture={() => handleShowSamlSection(values.email)}
                                    onBlur={handleBlur}
                                    onInvalid={handleInvalid}
                                    max={EMAIL_MAX_LENGTH}
                                    required
                                />
                                <label
                                    htmlFor="email"
                                    className={classNames(loginStyle.label, {
                                        [loginStyle.inputError]: errors.email && touched.email,
                                    })}
                                >
                                    {t("LoginForm.emailAddress")}
                                </label>
                                <div
                                    className={classNames(form.error, form.errorStationary)}
                                    data-testid={testIds.login.form.usernameInput.errorLabel}
                                >
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                        </div>
                        <div hidden={passwordLoginSectionHidden}>
                            <div className={loginStyle.formFields}>
                                <div className={classNames(loginStyle.field)}>
                                    <button
                                        onClick={() => togglePasswordVisibility((prevState) => !prevState)}
                                        className={classNames(
                                            loginStyle.passwordVisibilityToggle,
                                            passwordVisible ? loginStyle.hidePassword : loginStyle.showPassword
                                        )}
                                        aria-label={passwordVisible ? t("LoginForm.hide") : t("LoginForm.show")}
                                        data-testid={testIds.login.form.passwordInput.toggle}
                                        type="button"
                                    />
                                    <input
                                        id="password"
                                        data-testid={testIds.login.form.passwordInput.itself}
                                        type={passwordVisible ? "text" : "password"}
                                        className={
                                            samlLoginSectionHidden
                                                ? props.loginErrorMessage != ""
                                                    ? classNames(loginStyle.input, loginStyle.inputEmailError)
                                                    : classNames(loginStyle.input, {
                                                          [loginStyle.inputEmailError]: errors.password,
                                                      })
                                                : loginStyle.input
                                        }
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onInvalid={handleInvalid}
                                        required
                                    />

                                    <label
                                        htmlFor="password"
                                        className={
                                            samlLoginSectionHidden
                                                ? props.loginErrorMessage != ""
                                                    ? classNames(loginStyle.label, loginStyle.inputError)
                                                    : classNames(loginStyle.label, {
                                                          [loginStyle.inputError]: errors.password && touched.password,
                                                      })
                                                : loginStyle.label
                                        }
                                    >
                                        {t("LoginForm.password")}
                                    </label>
                                    <div
                                        className={classNames(form.error, form.errorStationary)}
                                        data-testid={testIds.login.form.passwordInput.errorLabel}
                                        hidden={!samlLoginSectionHidden}
                                    >
                                        <ErrorMessage name="password" />
                                    </div>
                                </div>
                            </div>
                            <div className={loginStyle.loginError} data-testid={testIds.login.form.errorLabel}>
                                {props.loginErrorMessage}
                            </div>
                            <div className={classNames(form.buttonContainer, form.buttonContainerVertical)}>
                                <button
                                    type="submit"
                                    className={loginStyle.submitButton}
                                    disabled={isSubmitting}
                                    data-testid={testIds.login.form.submitButton}
                                >
                                    {t("Common.loginTitle")}
                                </button>
                            </div>
                        </div>
                        <div
                            hidden={samlLoginSectionHidden}
                            className={classNames(form.buttonContainer, loginStyle.samlButtonContainer)}
                        >
                            <div className={loginStyle.orSeparator} hidden={passwordLoginSectionHidden}>
                                <line className={loginStyle.separatorLine}></line>
                                <label className={loginStyle.samlLabel}>{t("Common.or")}</label>
                                <line className={loginStyle.separatorLine}></line>
                            </div>
                            <button
                                onClick={() => {
                                    window.location.replace(samlLoginLink);
                                }}
                                type="submit"
                                className={loginStyle.submitButton}
                                disabled={isSubmitting}
                                data-testid={testIds.login.form.samlSubmitButton}
                            >
                                {t("LoginForm.logInWithCompanyId")}
                            </button>
                            <label className={loginStyle.samlLabel}>
                                <span>{t("LoginForm.companyId")}</span>
                                {t("LoginForm.companyIdInfo")}
                            </label>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
}
