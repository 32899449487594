import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const PATH_REPORT_IMPORT_JOB_PREFIX = "/api/import-jobs";
const PATH_REPORT_IMPORT_VALIDATE = "validate";

export type ReportImportJobStatus = "WAITING" | "VALIDATED" | "IN_PROGRESS" | "DONE" | "DONE_WITH_FAILURES" | "FAILED";
export type ReportImportJobReportStatus = "VALIDATED" | "VERIFIED" | "INDEXED" | "EXTRACTED" | "FAILED";
export type ReportImportJobType = "NORMAL" | "BULK";

export interface ImportNotificationDataDto {
    job_id: string | null;
    job_type: ReportImportJobType;
}

export interface ImportNotificationData {
    jobId: string | null;
    jobType: ReportImportJobType;
}

export interface InitializeJobResponseDto {
    job_id: string;
}

export interface InitializeJobResponse {
    jobId: string;
}

export interface UploadUrl {
    url: string;
    key: string;
}

export interface FetchUploadUrlsResponse {
    urls: UploadUrl[];
}

type ReportImportFileType = "ALBUS" | "REAL_STANDALONE";

type FetchImportJobSource = "IMPORT" | "NOTIFICATION";

interface CommonReportImportJobReport {
    uuid: string;
    filename: string;
    status: ReportImportJobReportStatus;
}

interface ReportImportLicenseDto {
    product_id: number;
    amount: number;
}

export interface ReportImportLicense {
    productId: number;
    amount: number;
}

interface ReportImportJobReportDto extends CommonReportImportJobReport {
    last_processed: string;
    failure_reason?: string;
    file_type: ReportImportFileType;
    licenses: ReportImportLicenseDto[];
}

export interface ReportImportJobReport extends CommonReportImportJobReport {
    lastProcessed: string;
    failureReason?: string;
    fileType: ReportImportFileType;
    licenses: ReportImportLicense[];
}

export interface ReportImportJobResponseDto {
    cursor: string[];
    status: ReportImportJobStatus;
    reports: ReportImportJobReportDto[];
    total_count: number;
    succeeded_count: number;
    in_progress_count: number;
    failed_count: number;
    validated_count: number;
}

export interface ReportImportJobResponse {
    cursor: string[];
    status: ReportImportJobStatus;
    reports: ReportImportJobReport[];
    totalCount: number;
    succeededCount: number;
    inProgressCount: number;
    failedCount: number;
    validatedCount: number;
}

export interface BucketKeysRequest {
    totalReports: number;
    bucketKeys: string[];
}

export interface BucketKeysRequestDto {
    total_reports: number;
    bucket_keys: string[];
}

function toReportImportJobReport(dto: ReportImportJobReportDto): ReportImportJobReport {
    return {
        filename: dto.filename,
        uuid: dto.uuid,
        status: dto.status,
        lastProcessed: dto.last_processed,
        fileType: dto.file_type,
        failureReason: dto.failure_reason,
        licenses: dto.licenses.map((license) => ({
            productId: license.product_id,
            amount: license.amount,
        })),
    };
}

function toReportImportJobResponse(dto: ReportImportJobResponseDto): ReportImportJobResponse {
    return {
        cursor: dto.cursor,
        status: dto.status,
        reports: dto.reports.map((report) => toReportImportJobReport(report)),
        totalCount: dto.total_count,
        succeededCount: dto.succeeded_count,
        inProgressCount: dto.in_progress_count,
        failedCount: dto.failed_count,
        validatedCount: dto.validated_count,
    };
}

function toBucketKeysRequestDto(request: BucketKeysRequest): BucketKeysRequestDto {
    return {
        bucket_keys: request.bucketKeys,
        total_reports: request.totalReports,
    };
}

export function toImportNotificationData(dto: ImportNotificationDataDto): ImportNotificationData {
    return {
        jobId: dto.job_id,
        jobType: dto.job_type,
    };
}

class ReportImportService {
    public initializeJob(bulk: boolean, abortController?: AbortController): Promise<InitializeJobResponse> {
        let url = PATH_REPORT_IMPORT_JOB_PREFIX;
        if (bulk) {
            const urlSearchParams = new URLSearchParams();
            urlSearchParams.append("type", "BULK");
            url += "?" + urlSearchParams.toString();
        }
        return apiGatewayService
            .invokeApi(url, "POST", null, {
                abortSignal: abortController?.signal,
                refreshSession: true,
                apiType: ApiType.OLIVER,
            })
            .then((response: InitializeJobResponseDto) => {
                return Promise.resolve({ jobId: response.job_id });
            });
    }

    public fetchUploadUrls(
        jobId: string,
        filenames: string[],
        abortController?: AbortController
    ): Promise<FetchUploadUrlsResponse> {
        return apiGatewayService.invokeApi(
            `${PATH_REPORT_IMPORT_JOB_PREFIX}/${jobId}/get-upload-urls`,
            "POST",
            {
                filenames: filenames,
            },
            { abortSignal: abortController?.signal, refreshSession: true, apiType: ApiType.LAUREL }
        );
    }

    public validateReports(
        jobId: string,
        request: BucketKeysRequest,
        abortController?: AbortController
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            `${PATH_REPORT_IMPORT_JOB_PREFIX}/${jobId}/${PATH_REPORT_IMPORT_VALIDATE}`,
            "POST",
            toBucketKeysRequestDto(request),
            {
                abortSignal: abortController?.signal,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }

    public fetchReportImportJob(
        jobId: string,
        cursor: string[],
        jobType: ReportImportJobType,
        source: FetchImportJobSource,
        abortController?: AbortController
    ): Promise<ReportImportJobResponse> {
        const urlSearchParams = new URLSearchParams();
        if (cursor != null && cursor.length == 3) {
            cursor.forEach((cursorPart) => {
                urlSearchParams.append("cursor", cursorPart);
            });
        }

        urlSearchParams.append("type", jobType);
        urlSearchParams.append("source", source);

        const param = urlSearchParams.toString();
        let url = PATH_REPORT_IMPORT_JOB_PREFIX + "/" + jobId;
        if (param !== "") {
            url += "?" + param;
        }
        return apiGatewayService
            .invokeApi(url, "GET", null, {
                abortSignal: abortController?.signal,
                refreshSession: true,
                apiType: ApiType.OLIVER,
            })
            .then((response: ReportImportJobResponseDto) => Promise.resolve(toReportImportJobResponse(response)));
    }

    public importReports(jobId: string, abortController: AbortController, request?: BucketKeysRequest): Promise<void> {
        return apiGatewayService.invokeApi(
            `${PATH_REPORT_IMPORT_JOB_PREFIX}/${jobId}/import`,
            "POST",
            request ? toBucketKeysRequestDto(request) : null,
            {
                abortSignal: abortController.signal,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }
}

export const reportImportService = new ReportImportService();
