import { TableData } from "./table";
import { Authority, RoleInUserResponse, RoleInUserResponseDto } from "domain/roles";
import { LoginMethod } from "domain/tenants";
import { DEFAULT_LOGIN_METHOD, UserLoginMethod } from "domain/user";

export enum UserStatus {
    UNCONFIRMED = "UNCONFIRMED",
    CONFIRMED = "CONFIRMED",
    ARCHIVED = "ARCHIVED",
    COMPROMISED = "COMPROMISED",
    UNKNOWN = "UNKNOWN",
    RESET_REQUIRED = "RESET_REQUIRED",
    FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}

export const FEATURE_LICENSES = [
    "FEATURE_CUSTOM_REPORT_VIEWS",
    "FEATURE_CUSTOM_ROLES",
    "FEATURE_OVERVIEW_DASHBOARD",
    "FEATURE_PUBLIC_APIS",
    "FEATURE_REPORTING",
    "FEATURE_REPORT_CUSTOMIZATION",
    "FEATURE_SSO",
    "FEATURE_SUSTAINABILITY_DASHBOARD",
    "FEATURE_TENANT_MANAGEMENT",
    "FEATURE_USER_GROUPS",
    "FEATURE_WORKFLOW_BDE",
    "FEATURE_WORKFLOW_BMDE",
    "FEATURE_WORKFLOW_LUN",
    "FEATURE_LICENSE_POOLS",
    "FEATURE_LICENSE_ALERTS",
] as const;
export type FeatureLicenseType = typeof FEATURE_LICENSES[number];

export function toFeatureLicenseType(id: string): FeatureLicenseType {
    return id.toUpperCase().replace(new RegExp("-", "g"), "_") as FeatureLicenseType;
}

export interface UsersData {
    count: number;
    cursor: string;
    userTableData: UserTableData[];
}

export interface UserTableData extends TableData {
    name: string;
    groups: Group[];
    status: UserStatus;
    createdDate: string;
    email: string;
    uuid: string;
    emailVerified: boolean;
    roleUuid: string;
    expirationDate: string;
    enabled: boolean;
    loginMethod: UserLoginMethod;
    checked?: boolean;
    poolName: string;
    userType: string;
}
export interface GroupDto {
    group_uuid: string;
    name: string;
    background_color: string;
    text_color: string;
}

export interface Group {
    groupUuid: string;
    name: string;
    backgroundColor: string;
    textColor: string;
}
export interface UserDataDto {
    name: string;
    groups: GroupDto[];
    status: UserStatus;
    createdDate: string;
    email: string;
    uuid: string;
    emailVerified: boolean;
    roleUuid: string;
    expiration_date: string;
    enabled: boolean;
    login_method: LoginMethod;
    pool_name: string;
    user_type: string;
}

export interface UsersResponseDto {
    user_list: UserDataDto[];
    count: number;
    cursor: string;
}

export function toUserData(dto: UserDataDto): UserTableData {
    const groups: Group[] = [];
    dto.groups &&
        dto.groups.map((group: GroupDto) => {
            groups.push({
                groupUuid: group.group_uuid,
                name: group.name,
                backgroundColor: group.background_color,
                textColor: group.text_color,
            });
        });
    return {
        name: dto.name,
        groups: groups,
        status: dto.status,
        createdDate: dto.createdDate,
        email: dto.email,
        uuid: dto.uuid,
        emailVerified: dto.emailVerified,
        roleUuid: dto.roleUuid,
        expirationDate: dto.expiration_date,
        enabled: dto.enabled,
        loginMethod: dto.login_method ?? DEFAULT_LOGIN_METHOD,
        poolName: dto.pool_name,
        userType: dto.user_type,
    };
}

export interface GroupWithRoleDto {
    group_uuid: string;
    name: string;
    background_color: string;
    text_color: string;
    role: RoleInUserResponseDto;
    user_count: number;
    created_date: string;
}

export interface GroupWithRole {
    groupUuid: string;
    name: string;
    backgroundColor: string;
    textColor: string;
    role?: RoleInUserResponse;
    userCount: number;
    createdDate: string;
}

export interface UserByUuidDto {
    created_date: string;
    enabled: boolean;
    email: string;
    email_verified: boolean;
    expiration_date: string;
    groups: GroupWithRoleDto[];
    name: string;
    role: RoleInUserResponseDto;
    status: string;
    uuid: string;
    user_type: string;
}

export interface UserByUuid {
    createdDate: string;
    enabled: boolean;
    email: string;
    emailVerified: boolean;
    expirationDate: string;
    groups: GroupWithRole[];
    name: string;
    role: RoleInUserResponse;
    status: string;
    uuid: string;
    userType: string;
}

function toGroupWithRole(group: GroupWithRoleDto): GroupWithRole {
    return {
        groupUuid: group.group_uuid,
        name: group.name,
        backgroundColor: group.background_color,
        textColor: group.text_color,
        role: group.role && toRoleInUserResponse(group.role),
        userCount: group.user_count,
        createdDate: group.created_date,
    };
}

function toRoleInUserResponse(role: RoleInUserResponseDto): RoleInUserResponse {
    return {
        authorities: role.authorities,
        authorityTargets: role.authority_targets,
        name: role.name,
        uuid: role.uuid,
    };
}

export function toUserByUuid(dto: UserByUuidDto): UserByUuid {
    return {
        createdDate: dto.created_date,
        enabled: dto.enabled,
        email: dto.email,
        emailVerified: dto.email_verified,
        expirationDate: dto.expiration_date,
        groups: dto.groups.map(toGroupWithRole),
        name: dto.name,
        role: toRoleInUserResponse(dto.role),
        status: dto.status,
        uuid: dto.uuid,
        userType: dto.user_type,
    };
}
export interface CheckEmailAvailability {
    emailIsAvailable: boolean;
    emailIsUndeliverable?: boolean;
    domainName?: string;
    errorMessage?: string;
}

interface CommonRole {
    name: string;
    authorities: string[];
}

export interface Role extends CommonRole {
    uuid: string;
    defaultNewUserRole: boolean;
}

export interface RoleDto extends CommonRole {
    role_uuid: string;
    default_new_user_role: boolean;
}

export function toRole(dto: RoleDto): Role {
    return {
        name: dto.name,
        authorities: dto.authorities,
        uuid: dto.role_uuid,
        defaultNewUserRole: dto.default_new_user_role,
    };
}

export interface UserRolesTableData extends TableData {
    name: string;
    roleUuid: string;
    description: string;
    createdDate: string;
    editedDate: string;
    creator: string;
    defaultRole: boolean;
    authorities: Authority[];
}

export interface SsoConfiguration {
    loginUrl: string;
    providerName: string;
    protocol: string;
    metadataUrl: string;
    clientId: string;
    clientSecret: string;
    oidcIssuer: string;
    ssoUrl: string;
    entityId: string;
}
