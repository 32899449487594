// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ltZSSGHHKJRkTjjrMt4GR{padding:12px 16px;width:312px;height:58px;background:#f6f3fa;border:1px solid #ededed;border-radius:8px;display:flex;align-items:center;justify-content:space-between}._2q9Km_jaT4pdOIWW8-aSNo{font-style:normal;font-weight:600;font-size:10px;line-height:14px;letter-spacing:.02em;text-transform:uppercase;color:#606060}.w6HzEsdPPfYdKHp3rLt6g{font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#303030}._2p4rUy5FMG7qrr36_cQ7sP{font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#3b5dab}", ""]);
// Exports
exports.locals = {
	"currentPlan": "_1ltZSSGHHKJRkTjjrMt4GR",
	"planLabel": "_2q9Km_jaT4pdOIWW8-aSNo",
	"planValue": "w6HzEsdPPfYdKHp3rLt6g",
	"bundlesLabel": "_2p4rUy5FMG7qrr36_cQ7sP"
};
module.exports = exports;
