import { TableData } from "domain/table";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { ParameterQuery } from "utils/commonFunctions";

const PATH = "/api/v1/license-alerts";

interface LicenseAlertDto {
    alert_name: string;
    alert_uuid: string;
    tenant_uuid: string;
    tenant_name: string;
    creation_date: string;
    pool_name: string;
    pool_uuid: string;
}

export interface LicenseAlertsResponse {
    alerts: LicenseAlert[];
    count: number;
    cursor: string;
}

export interface LicenseAlert extends TableData {
    alertName: string;
    alertUuid: string;
    tenantName: string;
    creationDate: string;
    poolName: string;
    poolUuid: string;
}

interface ResponseData {
    alerts: LicenseAlertDto[];
}

interface LicenseAlertsResponseDto {
    response: ResponseData;
    count: number;
    cursor: string;
}

export interface LicenseAlertDetails {
    product: string;
    amount_threshold: number | undefined;
    expiration_threshold: number | undefined;
    triggered?: boolean;
}
export interface CreateAlertRequest {
    alertName: string;
    tenantUuid: string;
    licenses: LicenseAlertDetails[];
    poolUuid: string | null;
}

export interface CreateLicenseAlertDto {
    alert_name: string;
    licenses: LicenseAlertDetails[];
    tenant_uuid: string;
    pool_uuid: string | null;
}

const toLicenseAlertsResponse = (data: LicenseAlertsResponseDto): LicenseAlertsResponse => {
    return {
        alerts: data.response.alerts.map((alert: LicenseAlertDto) => ({
            alertName: alert.alert_name,
            alertUuid: alert.alert_uuid,
            tenantUuid: alert.tenant_uuid,
            creationDate: alert.creation_date,
            tenantName: alert.tenant_name,
            poolName: alert.pool_name,
            poolUuid: alert.pool_uuid,
        })),
        count: data.count,
        cursor: data.cursor,
    };
};

interface LicenseAlertResultDto {
    alert: {
        alert_name: string;
        alert_uuid: string;
        tenant_uuid: string;
        tenant_name: string;
        licenses: LicenseDto[];
        pool_name: string;
        pool_uuid: string;
    };
}
export interface License {
    type: string;
    amountThreshold: number;
    expirationThreshold: number;
    triggered: boolean;
    expirationDate?: string;
    remainingLicenses?: number;
}

interface ResponseData {
    alert: Alert;
}

interface LicenseDto {
    product: string;
    amount_threshold: number | null;
    expiration_threshold: number | null;
    triggered: boolean;
}
export interface Alert {
    alertName: string;
    alertUuid: string;
    tenantUuid: string;
    tenantName: string;
    poolUuid: string;
    licenses: License[];
    poolName: string;
}

export interface LicenseAlertResult {
    alert: Alert;
}

export interface EditLicenseAlertDto {
    alert_name: string;
    licenses: LicenseAlertDetails[];
    tenant_uuid: string;
    pool_uuid?: string;
}

const toAlertResponse = (data: LicenseAlertResultDto): LicenseAlertResult => {
    return {
        alert: {
            alertName: data.alert.alert_name,
            alertUuid: data.alert.alert_uuid,
            tenantUuid: data.alert.tenant_uuid,
            tenantName: data.alert.tenant_name,
            poolName: data.alert.pool_name,
            poolUuid: data.alert.pool_uuid,
            licenses: data.alert.licenses.map((license) => ({
                type: license.product,
                amountThreshold: license.amount_threshold === null ? Number.NaN : license.amount_threshold,
                expirationThreshold: license.expiration_threshold === null ? Number.NaN : license.expiration_threshold,
                triggered: license.triggered,
            })),
        },
    };
};

class LicenseAlertService {
    public fetchAllLicenseAlerts(
        abortController?: AbortController,
        cursor?: string,
        search?: string,
        name?: string
    ): Promise<LicenseAlertsResponse> {
        const query = new ParameterQuery()
            .add("search", search)
            .add("name", name)
            .add("cursor", cursor ? JSON.stringify(cursor) : cursor)
            .createQueryString();
        return apiGatewayService
            .invokeApi(`${PATH}${query}`, "GET", null, {
                abortSignal: abortController?.signal,
                apiType: ApiType.LAUREL,
            })
            .then((dto: LicenseAlertsResponseDto) => toLicenseAlertsResponse(dto));
    }

    public fetchLicenseAlertByUuid(uuid: string, abortController: AbortController): Promise<LicenseAlertResult> {
        return apiGatewayService
            .invokeApi(PATH + "/" + uuid, "GET", null, {
                apiType: ApiType.LAUREL,
                abortSignal: abortController.signal,
            })
            .then((dto: LicenseAlertResultDto) => toAlertResponse(dto));
    }

    public async createLicenseAlert(alert: CreateAlertRequest, abortController: AbortController) {
        const postData: CreateLicenseAlertDto = {
            alert_name: alert.alertName,
            licenses: alert.licenses,
            tenant_uuid: alert.tenantUuid,
            pool_uuid: alert?.poolUuid,
        };
        return apiGatewayService.invokeApi(PATH, "POST", postData, {
            abortSignal: abortController.signal,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public deleteLicenseAlerts(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH + "/" + uuid, "DELETE", null, {
            abortSignal: abortController.signal,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public editLicenseAlerts(uuid: string, alert: CreateAlertRequest, abortController: AbortController): Promise<void> {
        const postData: EditLicenseAlertDto = {
            alert_name: alert.alertName,
            licenses: alert.licenses,
            tenant_uuid: alert.tenantUuid,
            pool_uuid: "",
        };
        return apiGatewayService.invokeApi(PATH + "/" + uuid, "POST", postData, {
            abortSignal: abortController.signal,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }
}

export const licenseAlertsService = new LicenseAlertService();
