import { Authority, RoleTable, Targets, toUserRoleData, UserRolesResponseDto } from "domain/roles";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const PATH_ROLES = "/api/v1/roles";

function isNotEmpty(value?: string): value is string {
    return value != null && value !== "";
}

class RolesService {
    public generateRoles(
        name: string,
        description?: string,
        authorities?: Authority[],
        targets?: Targets | string,
        abortController?: AbortController
    ): Promise<void> {
        if (targets == undefined) {
            targets = "NONE";
        }
        return apiGatewayService.invokeApi(
            PATH_ROLES,
            "POST",
            { name, description, authorities, targets },
            {
                abortSignal: abortController?.signal,
                apiType: ApiType.LAUREL,
            }
        );
    }

    public async fetchRoles(
        abortController: AbortController,
        name?: string,
        cursor?: string,
        search?: string
    ): Promise<RoleTable> {
        const urlSearchParams = new URLSearchParams();
        if (isNotEmpty(search)) {
            urlSearchParams.append("search", search);
        }

        if (isNotEmpty(name)) {
            urlSearchParams.append("name", name);
        }

        if (isNotEmpty(cursor)) {
            urlSearchParams.append("cursor", cursor);
        }

        const param = urlSearchParams.toString();
        let api_url = PATH_ROLES;
        if (param !== "") {
            api_url += "?" + param;
        }
        return await apiGatewayService
            .invokeApi(api_url, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.LAUREL,
            })
            .then((dto: UserRolesResponseDto) => {
                return {
                    cursor: dto.cursor,
                    roles: dto.roles.map(toUserRoleData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch user roles.");
            });
    }

    public editRole(
        uuid: string,
        name: string,
        description?: string,
        authorities?: Authority[],
        targets?: Targets | string,
        abortController?: AbortController
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            PATH_ROLES + "/" + uuid,
            "POST",
            { name, description, authorities, targets },
            {
                abortSignal: abortController?.signal,
                apiType: ApiType.LAUREL,
            }
        );
    }

    public setDefault(uuid: string, abortController: AbortController): Promise<void> {
        const apiPath = PATH_ROLES + "/" + uuid + "/?default=true";
        return apiGatewayService.invokeApi(apiPath, "POST", null, {
            abortSignal: abortController.signal,
            refreshSession: true,
            apiType: ApiType.LAUREL,
        });
    }

    public deleteRole(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH_ROLES + "/" + uuid, "DELETE", null, {
            abortSignal: abortController.signal,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }
}

export const rolesService = new RolesService();
