import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import useScript from "react-script-hook";

import style from "./wasm-visual-editor-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { compareEditorVersionDtos } from "components/workflows/WorkflowUtil";
import { Workflow, WorkflowEntityType } from "domain/workflows";
import { getOliverUrl } from "services/login/endpointRepository";
import { ManifestWorkflowEditor, Profile } from "services/workflows/WorkflowService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    tenantUuid: state.userReducer.user?.tenantUuid,
});

export type ActionType = "create" | "edit" | "update";

interface Props {
    profile: Profile;
    version?: string;
    workflow?: Workflow;
    workflowEditors: ManifestWorkflowEditor[];
    setWorkflow?: (workflow: Workflow) => void;
    workflowName?: string;
    uuid?: string;
    actionType: ActionType;
    entityType: WorkflowEntityType;
    bmdeDiagnostics?: string;
    preventNameChange: boolean;
    setHasNewEditorLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

const connector = connect(mapState, {});
const WasmVisualEditorView = (props: ConnectedProps<typeof connector> & Props): JSX.Element => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const getEditorUrl = (editor: ManifestWorkflowEditor) => {
        const split = editor.url.split("workflow-editors");
        return window.location.hostname == "localhost"
            ? process.env.GLOBAL_URL + "/workflow-editors" + split[1]
            : "workflow-editors" + split[1];
    };

    const pickWorkflowEditor = (profile: Profile, version?: string): string => {
        const productProfile = profile === Profile.BMDE || profile === Profile.BMDE_DIAGNOSTIC ? Profile.BMDE : profile;
        const sorted = props.workflowEditors
            .filter((editor) => editor.profile === productProfile && editor.editorGeneration == "V2")
            .sort(compareEditorVersionDtos);
        if (version) {
            const editor = sorted.find((editor) => editor.version === version);
            if (editor) {
                return getEditorUrl(editor);
            } else {
                const versionSplit = version.split(".");
                if (versionSplit.length > 3) {
                    versionSplit.pop();
                    const editor = sorted.find((editor) => editor.version === versionSplit.join("."));
                    if (editor) {
                        return getEditorUrl(editor);
                    }
                }
            }
        }

        return getEditorUrl(sorted[0]);
    };

    const url = pickWorkflowEditor(props.profile, props.version);

    const [bootstrapEditorScriptLoading, bootstrapEditorScriptLoadingError] = useScript({
        src: url + "editor.js",
        checkForExisting: false,
    });
    const [bootstrapQtLoaderScriptLoading, bootstrapQtLoaderScriptLoadingError] = useScript({
        src: url + "qtloader.js",
        checkForExisting: false,
    });

    if (bootstrapEditorScriptLoadingError) {
        console.error("Failed to load editor source: " + bootstrapEditorScriptLoadingError);
        return <></>;
    }

    if (bootstrapQtLoaderScriptLoadingError) {
        console.error("Failed to load qtloader source: " + bootstrapQtLoaderScriptLoadingError);
        return <></>;
    }

    const canvasElementId = "screen";

    const initEditor = async () => {
        const parameters = ["httpApiBaseUrl=" + getOliverUrl()];
        if (props.workflowName) {
            parameters.push("newDocumentName=" + props.workflowName);
        }
        if (props.uuid) {
            parameters.push("docCloudUuid=" + props.uuid);
        }
        if (props.actionType) {
            parameters.push("actionType=" + props.actionType);
        }
        if (props.bmdeDiagnostics == Profile.BMDE_DIAGNOSTIC) {
            parameters.push("newDocumentProfile=4");
        }
        if (props.preventNameChange) {
            parameters.push("preventNameChange=" + props.preventNameChange);
        }
        if (props.entityType === "TEMPLATE") {
            parameters.push("template=true");
        } else if (props.entityType === "SUB_WORKFLOW") {
            parameters.push("subWorkflow=true");
            parameters.push("template=true");
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.wasmInstance = await qtLoad({
            arguments: parameters,
            qt: {
                onLoaded: () => {
                    setLoading(false);
                    props.setHasNewEditorLoaded && props.setHasNewEditorLoaded(true);
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                entryFunction: window.createQtAppInstance,
                containerElements: [document.getElementById(canvasElementId)],
            },
        });
    };

    React.useEffect(() => {
        if (
            bootstrapEditorScriptLoading ||
            bootstrapEditorScriptLoadingError ||
            bootstrapQtLoaderScriptLoading ||
            bootstrapQtLoaderScriptLoadingError ||
            typeof props.tenantUuid === "undefined"
        ) {
            return;
        }

        initEditor();
    }, [bootstrapEditorScriptLoading, bootstrapQtLoaderScriptLoading]);

    return (
        <div id={canvasElementId} className={style.editorCanvas}>
            {loading && <LoadingIndicator />}
        </div>
    );
};

export default connector(WasmVisualEditorView);
