import classNames from "classnames";
import { useFeature } from "flagged";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { endOfDay, startOfDay, subDays } from "rsuite/esm/utils/dateUtils";

import SeparatorField from "./SeparatorField";
import TemplateSelection from "./TemplateSelection";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import modalStyle from "components/reports/create-report-view-modal.scss";
import style from "components/reports/erasure-reports-table.scss";
import dialogStyle from "components/reports/export/export-report-dialog.scss";
import Swap from "components/reports/Swap";
import Tooltip from "components/tooltip/Tooltip";
import { CustomReportViewFilterGroup, MAX_COLUMN_COUNT, TemplateTableData } from "domain/reports";
import { FLAG_EXPORT_REPORTS_FILENAME } from "services/feature/FeatureFlagService";
import { getLanguage } from "services/language/languageRepository";
import { createTranslatePath } from "services/pathTranslator";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";
import { reportViewService } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantHasFeatureLicense } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

interface Props {
    columns?: string[];
    handle: (values: FormValues) => Promise<void>;
    startDate?: string;
    endDate?: string;
    search: string;
    onShowModal: (doShow: boolean) => void;
    isAllReportsView: boolean;
    filters?: CustomReportViewFilterGroup;
    language?: string;
    reportUuids?: string[];
    hasOnlyBmsReports?: boolean;
    totalReportCount?: number;
    templateUuid?: string;
    formatType?: string;
    singleReportExport: boolean;
}

export interface FormValues {
    format: string;
    startDate?: string;
    endDate?: string;
    search: string;
    templateUuid: string | null;
    columns?: string[];
    filters?: CustomReportViewFilterGroup;
    language?: string;
    reportUuids?: string[];
    filenameSeparator?: string;
    subRecordSeparator?: string;
    recordSeparator?: string;
    filenameColumns?: string[];
    combined: boolean;
    humanReadableNumbers: boolean;
    localizedColumns: boolean;
}

const enum ExportFormat {
    PDF = "PDF",
    CSV = "CSV",
    XML = "XML",
    SUMMARY_CSV = "SUMMARY_CSV",
    SUMMARY_PDF = "SUMMARY_PDF",
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const DEFAULT_COLUMN_NAME = "defaultColumnName";
const DEFAULT_CSV_COLUMN_NAME = "defaultCsvColumnName";
const CUSTOM_COLUMN_NAME = "customColumnName";
const CUSTOM_CSV_COLUMN_NAME = "customCsvColumnName";

const ExportReportDialog = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const translatePath = createTranslatePath(useTranslation);
    const [templates, setTemplates] = React.useState<TemplateTableData[]>([]);
    const [templateUuid, setTemplateUuid] = React.useState<string | null>(null);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const paths = reportViewService.getPaths();
    const [failureError, setFailureError] = React.useState<string>("");
    const [disableFormat, setDisableFormat] = React.useState<boolean>(false);
    const [enabledExportButton, setEnabledExportButton] = React.useState<boolean>(false);
    const [csvColumnCount, setCsvColumnCount] = React.useState<number>(0);
    const [fileNameColumnCount, setFileNameColumnCount] = React.useState<number>(0);
    const [formatType, setFormatType] = React.useState("XML");
    const [separatorValue, setSeparatorValue] = React.useState("_");
    const [recordSeparator, setRecordSeparator] = React.useState(",");
    const [subRecordSeparator, setSubRecordSeparator] = React.useState(";");
    const [combineFile, setCombineFile] = React.useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = React.useState<boolean>(false);
    const [humanReadableNumbers, setHumanReadableNumbers] = React.useState<boolean>(true);
    const [localizedColumns, setLocalizedColumn] = React.useState<boolean>(true);
    const [csvSelectedColumns, setCsvSelectedColumns] = React.useState<string[]>();
    const filenameSeparatorList = "@._;+,#-";
    const csvSeparatorList = filenameSeparatorList + ":";
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [customCsvColumnNames, setCustomCsvColumnNames] = React.useState(DEFAULT_CSV_COLUMN_NAME);
    const [customColumnNames, setCustomColumnNames] = React.useState(DEFAULT_COLUMN_NAME);
    const formatTypeCSV = formatType === ExportFormat.CSV || formatType === ExportFormat.SUMMARY_CSV;
    const formatTypeCheck = formatTypeCSV || formatType === ExportFormat.SUMMARY_PDF;
    const fetchTemplates = (): void => {
        // Allow users with basic access to reporting but no access to templates to export reports with the default
        // template.
        if (tenantHasFeatureLicense("FEATURE_REPORTING") && !tenantHasFeatureLicense("FEATURE_REPORT_CUSTOMIZATION")) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportTemplateService
            .fetchTemplates(abortController)
            .then((data) => {
                setTemplates(data);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setFailureError(t("viewTemplate.requestFailed"));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (templates.length > 0) {
            const defaultTemplate = templates.filter((template) => template.defaultReportTemplate.own).shift();
            setTemplateUuid(defaultTemplate ? defaultTemplate.uuid : null);
        }
    }, [templates]);

    React.useEffect(() => {
        fetchTemplates();
        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, []);

    React.useEffect(() => {
        if (props.hasOnlyBmsReports) {
            setFormatType("CSV");
            setDisableFormat(true);
        }
    }, [props.hasOnlyBmsReports]);

    const handleCsvDataOptionChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        setCustomCsvColumnNames(value);
        setCsvColumnCount(0);
    };
    const createCsvDataSection = () => {
        return (
            <div className={dialogStyle.exportFilenameDiv}>
                <label className={dialogStyle.exportFilenameLabal}>{t("ErasureReport.fileName.csvTitle")}</label>
                <div>
                    <input
                        id="defaultCsvName"
                        type="radio"
                        className={dialogStyle.exportRadioButton}
                        value={DEFAULT_CSV_COLUMN_NAME}
                        name="csvSection"
                        data-testid={testIds.workArea.report.erasure.exportReportDialog.csvDataRadioButton}
                        defaultChecked={customCsvColumnNames === DEFAULT_CSV_COLUMN_NAME}
                        onChange={handleCsvDataOptionChange}
                    />
                    <label htmlFor="defaultCsvName">{t("ErasureReport.fileName.csvDefaultColumns")}</label>
                    <br />
                    <input
                        id="customCsvName"
                        type="radio"
                        className={dialogStyle.exportRadioButton}
                        value={CUSTOM_CSV_COLUMN_NAME}
                        name="csvSection"
                        data-testid={testIds.workArea.report.erasure.exportReportDialog.csvDataRadioButton}
                        onChange={handleCsvDataOptionChange}
                    />
                    <label htmlFor="customCsvName">{t("ErasureReport.fileName.csvCustomColumns")}</label>
                </div>
            </div>
        );
    };

    const createFilenameSection = () => {
        const handleColumnNameChange = (event: { target: { value: string } }) => {
            const value = event.target.value;
            setCustomColumnNames(value);
            setFileNameColumnCount(0);
        };
        return (
            <div className={dialogStyle.exportFilenameDiv}>
                <label className={dialogStyle.exportFilenameLabal}>{t("ErasureReport.fileName.title")}</label>
                <div>
                    <input
                        id="defaultName"
                        type="radio"
                        className={dialogStyle.exportRadioButton}
                        value={DEFAULT_COLUMN_NAME}
                        name="filenameSection"
                        data-testid={testIds.workArea.report.erasure.exportReportDialog.filenameRadioButton}
                        defaultChecked={customColumnNames === DEFAULT_COLUMN_NAME}
                        onChange={handleColumnNameChange}
                    />
                    <label htmlFor="defaultName">{t("ErasureReport.fileName.defaultColumns")}</label>
                    <br />
                    <input
                        id="customName"
                        type="radio"
                        className={dialogStyle.exportRadioButton}
                        value={CUSTOM_COLUMN_NAME}
                        name="filenameSection"
                        data-testid={testIds.workArea.report.erasure.exportReportDialog.filenameRadioButton}
                        onChange={handleColumnNameChange}
                    />
                    <label htmlFor="customName">{t("ErasureReport.fileName.customColumns")}</label>
                </div>
            </div>
        );
    };

    const createExportTypeSelection = () => {
        const localizations: Partial<Record<ExportFormat, string>> = {
            [ExportFormat.CSV]: t("ErasureReport.formatType.csv"),
            [ExportFormat.PDF]: t("ErasureReport.formatType.pdf"),
            [ExportFormat.XML]: t("ErasureReport.formatType.xml"),
            [ExportFormat.SUMMARY_PDF]: t("ErasureReport.formatType.summaryPdf"),
            [ExportFormat.SUMMARY_CSV]: t("ErasureReport.formatType.summaryCsv"),
        };

        if (props.singleReportExport) {
            delete localizations[ExportFormat.CSV];
        }

        return (
            <div className={classNames(style.filters)}>
                {props.reportUuids?.length == 1 && (
                    <>
                        <div>
                            <label htmlFor={"ReportUuid"} className={classNames(form.label, style.previewLabel)}>
                                {t("Common.reportUuid")}
                            </label>
                            <span>{props.reportUuids}</span>
                        </div>
                        <br />
                    </>
                )}
                <label htmlFor={"formats"} className={classNames(form.label, style.previewLabel)}>
                    {t("ErasureReport.reportFormat")}
                </label>
                <select
                    id={"formats"}
                    disabled={disableFormat}
                    className={classNames(form.select, form.fixedWidthInput, style.selectArrow)}
                    onChange={(event) => {
                        usageStatisticsService.sendEvent({
                            category: Category.REPORTS,
                            action: Action.PREVIEW_ERASURE_REPORT_WITH_TEMPLATE,
                        });
                        setFormatType(event.target.value);
                        setCustomColumnNames(DEFAULT_COLUMN_NAME);
                        setCustomCsvColumnNames(DEFAULT_CSV_COLUMN_NAME);
                    }}
                    defaultValue={formatType}
                    data-testid={testIds.workArea.report.erasure.exportReportDialog.formatSelect}
                >
                    {Object.entries(localizations)
                        .sort((first, second) => first[0].localeCompare(second[0]))
                        .map(([format, text]) => (
                            <option key={format} value={format}>
                                {text}
                            </option>
                        ))}
                </select>
            </div>
        );
    };

    const createDateFilterLabel = () => {
        const defaultDateRange = [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())];
        const startDateRange = createDateLabel(
            !props.startDate ? defaultDateRange[0].toISOString() : new Date(props.startDate).toISOString()
        );
        const endDateRange = createDateLabel(
            !props.endDate ? defaultDateRange[1].toISOString() : new Date(props.endDate).toISOString()
        );
        const dateRange = `${startDateRange} - ${endDateRange}`;
        const searchText = `"${props.search}"`;
        return (
            <div className={classNames(style.exportDateRange)}>
                {!props.search ? (
                    <div className={style.searchResultHintContainer}>
                        <label className={classNames(form.label, style.previewLabel)}>
                            {t("ErasureReport.exportMessage.sortMessage.date", { dateFilter: dateRange })}
                        </label>
                        <span>{dateRange}</span>
                    </div>
                ) : (
                    <div className={style.searchResultHintContainer}>
                        {t("ErasureReport.exportMessage.sortMessage.combined", {
                            dateFilter: dateRange,
                            searchFilter: searchText,
                        })}
                    </div>
                )}
            </div>
        );
    };

    function handleHideModal() {
        props.onShowModal(false);
    }

    const onAcceptCombinedReports = () => {
        setCombineFile(!combineFile);
    };

    const selectedReportsCount = props.totalReportCount || 0;
    const defaultColumns = customCsvColumnNames === DEFAULT_CSV_COLUMN_NAME ? props.columns : csvSelectedColumns;
    const defaultTemplateUuid = formatType === ExportFormat.PDF ? templateUuid : null;
    const defaultLanguage =
        formatType === ExportFormat.CSV ||
        formatType === ExportFormat.PDF ||
        formatType === ExportFormat.SUMMARY_CSV ||
        formatType === ExportFormat.SUMMARY_PDF
            ? getLanguage().code
            : undefined;
    return loading ? (
        <LoadingIndicator />
    ) : failureError ? (
        <div>
            <div className={modalStyle.resultMessageContainer}>{failureError}</div>
            <button
                className={classNames(buttonStyle.primaryButtonWithoutIcon, style.cancelButton)}
                type="button"
                onClick={handleHideModal}
                data-testid={testIds.common.dialog.closeButton}
            >
                {t("Common.close")}
            </button>
        </div>
    ) : (
        <div className={style.modal}>
            <div>{!props.reportUuids && createDateFilterLabel()}</div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    format: formatType,
                    startDate: props.startDate,
                    endDate: props.endDate,
                    search: props.search,
                    templateUuid: defaultTemplateUuid,
                    columns: defaultColumns,
                    filters: props.filters,
                    language: defaultLanguage,
                    reportUuids: props.reportUuids,
                    filenameSeparator: separatorValue,
                    filenameColumns: undefined,
                    combined: combineFile,
                    humanReadableNumbers: humanReadableNumbers,
                    localizedColumns: localizedColumns,
                    recordSeparator: recordSeparator,
                    subRecordSeparator: subRecordSeparator,
                }}
                validate={(values) => {
                    const errors: FormikErrors<FormValues> = {};
                    if (!values.filenameSeparator) {
                        errors.filenameSeparator = t("ErasureReport.fileName.errorMessage");
                    }
                    if (!values.recordSeparator) {
                        errors.recordSeparator = t("ErasureReport.fileName.errorMessage");
                    }
                    if (!values.subRecordSeparator) {
                        errors.subRecordSeparator = t("ErasureReport.fileName.errorMessage");
                    }
                    if (
                        values.recordSeparator &&
                        values.subRecordSeparator &&
                        values.recordSeparator === values.subRecordSeparator
                    ) {
                        errors.subRecordSeparator = t("ErasureReport.exportCsvProcessing.errorMessage");
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    props.handle(values);
                }}
                validateOnChange={hasSubmitted}
                validateOnBlur={hasSubmitted}
            >
                {({ errors, setFieldValue, submitCount }: FormikProps<FormValues>) => {
                    if (submitCount > 0) {
                        setHasSubmitted(true);
                    }
                    function updateColumns(paths: string[]) {
                        setFieldValue("columns", paths, true);
                        setCsvColumnCount(paths.length);
                        setCsvSelectedColumns(paths);
                    }
                    function updateFileNameColumns(paths: string[]) {
                        setFieldValue("filenameColumns", paths, true);
                        setFileNameColumnCount(paths.length);
                    }

                    function shouldExportButtonBeEnabled() {
                        if (
                            customCsvColumnNames === CUSTOM_CSV_COLUMN_NAME &&
                            csvColumnCount > 0 &&
                            customColumnNames === CUSTOM_COLUMN_NAME &&
                            fileNameColumnCount > 0
                        ) {
                            return true;
                        } else if (
                            (customCsvColumnNames === CUSTOM_CSV_COLUMN_NAME &&
                                csvColumnCount > 0 &&
                                customColumnNames != CUSTOM_COLUMN_NAME) ||
                            (customColumnNames === CUSTOM_COLUMN_NAME &&
                                fileNameColumnCount > 0 &&
                                customCsvColumnNames != CUSTOM_CSV_COLUMN_NAME)
                        ) {
                            return true;
                        } else if (
                            customColumnNames != CUSTOM_COLUMN_NAME &&
                            customCsvColumnNames != CUSTOM_CSV_COLUMN_NAME
                        ) {
                            return true;
                        } else if (combineFile) {
                            return true;
                        }
                        return false;
                    }

                    setEnabledExportButton(shouldExportButtonBeEnabled());

                    return (
                        <Form>
                            {formatType === ExportFormat.PDF ? (
                                <>
                                    <div className={classNames(style.header)}>{createExportTypeSelection()}</div>
                                    {selectedReportsCount > 1 && (
                                        <div className={(style.header, style.combineFileContainer)}>
                                            <label className={form.container}>
                                                <input
                                                    type="checkbox"
                                                    checked={combineFile}
                                                    className={form.input}
                                                    onClick={onAcceptCombinedReports}
                                                    data-testid={
                                                        testIds.workArea.report.erasure.exportReportDialog
                                                            .combineFileCheckbox
                                                    }
                                                />
                                                <span className={form.checkmark} />
                                            </label>
                                            <label>{t("ErasureReport.export.combineReportCheckboxLabel")}</label>
                                        </div>
                                    )}
                                    {tenantHasFeatureLicense("FEATURE_REPORT_CUSTOMIZATION") && (
                                        <div className={classNames(style.header)}>
                                            {
                                                <TemplateSelection
                                                    setTemplateUuid={setTemplateUuid}
                                                    templateUuid={templateUuid}
                                                    templates={templates}
                                                />
                                            }
                                        </div>
                                    )}
                                    {useFeature(FLAG_EXPORT_REPORTS_FILENAME) &&
                                        (!combineFile ? (
                                            <div className={classNames(style.header)}>{createFilenameSection()}</div>
                                        ) : (
                                            selectedReportsCount > 100 && (
                                                <div
                                                    className={(style.warningContainer, style.combineReportInfoMessage)}
                                                >
                                                    <div className={style.combineFileWarningIcon}>
                                                        <Warning
                                                            color={props.theme.warningIconBackgroundColor}
                                                            width={20}
                                                            height={20}
                                                            strokeColor={props.theme.warningIconExclamationColor}
                                                        />
                                                    </div>
                                                    {
                                                        <textarea
                                                            wrap="hard"
                                                            cols={47}
                                                            rows={selectedReportsCount > 1000 ? 5 : 3}
                                                            className={style.printWarningMessage}
                                                            defaultValue={
                                                                t("ErasureReport.export.combineReportInfoMessage") +
                                                                " " +
                                                                (selectedReportsCount > 1000
                                                                    ? t(
                                                                          "ErasureReport.export.combineReportInfoMessageExceedCount",
                                                                          {
                                                                              totalReportCount: selectedReportsCount,
                                                                              reportExcluded:
                                                                                  selectedReportsCount - 1000,
                                                                          }
                                                                      )
                                                                    : "")
                                                            }
                                                            readOnly
                                                        ></textarea>
                                                    }
                                                </div>
                                            )
                                        ))}
                                </>
                            ) : formatTypeCheck ? (
                                <>
                                    <div className={classNames(style.header)}>{createExportTypeSelection()}</div>
                                    {formatTypeCSV && (
                                        <div>
                                            <div className={dialogStyle.paddingTop}>
                                                <label className={classNames(form.label, style.previewLabel)}>
                                                    {t("ErasureReport.exportCsvProcessing.columnFormatting")}{" "}
                                                </label>
                                                <label
                                                    className={
                                                        (style.header,
                                                        dialogStyle.columnFormatCheckboxLabel,
                                                        form.container)
                                                    }
                                                >
                                                    <input
                                                        id="humanReadableNumbers"
                                                        type="checkbox"
                                                        checked={humanReadableNumbers}
                                                        className={form.input}
                                                        onClick={() => setHumanReadableNumbers(!humanReadableNumbers)}
                                                        data-testid={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .combineFileCheckbox
                                                        }
                                                    />
                                                    <span className={form.checkmark} />
                                                </label>
                                                <label htmlFor="humanReadableNumbers">
                                                    {t("ErasureReport.exportCsvProcessing.humanReadable")}
                                                </label>
                                                <Tooltip
                                                    content={
                                                        <>
                                                            {t(
                                                                "ErasureReport.exportCsvProcessing.humanReadableTooltip"
                                                            )}
                                                        </>
                                                    }
                                                    placement={"top"}
                                                >
                                                    <div className={form.infoIcon} tabIndex={0}>
                                                        <Info
                                                            borderColor={props.theme.contentBackgroundColor}
                                                            color={props.theme.iconFillColor}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div className={(style.header, dialogStyle.columnFormatCheckboxLabel)}>
                                                <label className={form.container}>
                                                    <input
                                                        id="localizedColumn"
                                                        type="checkbox"
                                                        checked={localizedColumns}
                                                        className={form.input}
                                                        onClick={() => setLocalizedColumn(!localizedColumns)}
                                                        data-testid={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .localizedColumnsCheckbox
                                                        }
                                                    />
                                                    <span className={form.checkmark} />
                                                </label>
                                                <label htmlFor="localizedColumn">
                                                    {t("ErasureReport.exportCsvProcessing.localizedColumns")}
                                                </label>
                                            </div>
                                            <div className={dialogStyle.paddingTop}>
                                                <div className={classNames(style.header)}>
                                                    <SeparatorField
                                                        id="recordSeparator"
                                                        separatorLabel={t("ErasureReport.fileName.separator")}
                                                        separator={recordSeparator}
                                                        updateSeparator={(value: string) => {
                                                            setRecordSeparator(value);
                                                            setFieldValue("recordSeparator", value);
                                                        }}
                                                        csvSeparatorValue={subRecordSeparator}
                                                        separatorData={csvSeparatorList}
                                                        dataTestId={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .recordSeparatorInput.itself
                                                        }
                                                        errorLabelDataTestId={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .recordSeparatorInput.errorLabel
                                                        }
                                                        errorMessage={errors.recordSeparator}
                                                    />
                                                </div>
                                                <div className={classNames(style.header)}>
                                                    <SeparatorField
                                                        id="subRecordSeparator"
                                                        separatorLabel={t(
                                                            "ErasureReport.exportCsvProcessing.subRecordSeparator"
                                                        )}
                                                        separator={subRecordSeparator}
                                                        updateSeparator={(value: string) => {
                                                            setSubRecordSeparator(value);
                                                            setFieldValue("subRecordSeparator", value);
                                                        }}
                                                        csvSeparatorValue={recordSeparator}
                                                        separatorData={csvSeparatorList}
                                                        dataTestId={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .subRecordSeparatorInput.itself
                                                        }
                                                        errorLabelDataTestId={
                                                            testIds.workArea.report.erasure.exportReportDialog
                                                                .subRecordSeparatorInput.errorLabel
                                                        }
                                                        errorMessage={errors.subRecordSeparator}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={classNames(style.header)}>{createCsvDataSection()}</div>
                                    {customCsvColumnNames === CUSTOM_CSV_COLUMN_NAME && formatTypeCheck && (
                                        <div
                                            data-testid={
                                                testIds.workArea.report.erasure.exportReportDialog.reportColumnContainer
                                                    .itself
                                            }
                                        >
                                            <Swap
                                                maximumSelectedCount={MAX_COLUMN_COUNT}
                                                onChange={updateColumns}
                                                paths={paths}
                                                t={t}
                                                create={props.isAllReportsView}
                                                columns={defaultColumns}
                                                theme={props.theme}
                                                translatePath={translatePath}
                                            />
                                            <div className={form.underline} />
                                        </div>
                                    )}
                                    {useFeature(FLAG_EXPORT_REPORTS_FILENAME) && props.reportUuids?.length == 1 && (
                                        <div className={classNames(style.header)}>{createFilenameSection()}</div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className={classNames(style.header)}>{createExportTypeSelection()}</div>
                                    {useFeature(FLAG_EXPORT_REPORTS_FILENAME) && (
                                        <div className={classNames(style.header)}>{createFilenameSection()}</div>
                                    )}
                                </>
                            )}
                            {customColumnNames === CUSTOM_COLUMN_NAME && !combineFile && (
                                <>
                                    <div className={classNames(style.header)}>
                                        {!combineFile && (
                                            <SeparatorField
                                                id="filenameSeparator"
                                                separatorLabel={t("ErasureReport.fileName.separator")}
                                                separator={separatorValue}
                                                updateSeparator={(value: string) => {
                                                    setSeparatorValue(value);
                                                    setFieldValue("filenameSeparator", value);
                                                }}
                                                separatorData={filenameSeparatorList}
                                                dataTestId={
                                                    testIds.workArea.report.erasure.exportReportDialog
                                                        .filenameSeparatorInput.itself
                                                }
                                                errorLabelDataTestId={
                                                    testIds.workArea.report.erasure.exportReportDialog
                                                        .filenameSeparatorInput.errorLabel
                                                }
                                                errorMessage={errors.filenameSeparator}
                                            />
                                        )}
                                    </div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.erasure.exportReportDialog.filenameColumnContainer
                                                .itself
                                        }
                                    >
                                        <Swap
                                            maximumSelectedCount={MAX_COLUMN_COUNT}
                                            onChange={updateFileNameColumns}
                                            paths={paths}
                                            t={t}
                                            create={props.isAllReportsView}
                                            columns={undefined}
                                            theme={props.theme}
                                            translatePath={translatePath}
                                        />
                                    </div>
                                </>
                            )}
                            {Object.keys(errors).length > 0 && (
                                <div className={dialogStyle.errorLabelBackground}>
                                    <FailedRedNotificationIcon
                                        backgroundColor={theme.errorIconColor}
                                        iconColor={theme.contentBackgroundColor}
                                    />
                                    <label
                                        className={dialogStyle.errorLabel}
                                        data-testid={testIds.common.dialog.errorBanner}
                                    >
                                        {t("ErasureReport.exportCsvProcessing.errorBanner")}
                                    </label>
                                </div>
                            )}

                            <div className={classNames(style.cancelButton, dialogStyle.exportButtonContainer)}>
                                <button
                                    className={buttonStyle.primaryButtonWithoutIcon}
                                    disabled={!enabledExportButton}
                                    type="submit"
                                    data-testid={testIds.workArea.report.erasure.exportReportDialog.exportButton}
                                >
                                    {t("Common.export")}
                                </button>
                                <button
                                    className={buttonStyle.secondaryButtonWithoutIcon}
                                    type="button"
                                    onClick={handleHideModal}
                                    data-testid={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.cancel")}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default connector(ExportReportDialog);
