import * as React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import WorkflowTemplatesTable from "./WorkflowTemplatesTable";
import style from "components/workflows/workflow-templates/workflow-templates.scss";
import { FilterState } from "domain/workflows";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

enum TabIndex {
    WORKFLOW_TEMPLATE,
    SUB_WORKFLOW,
}

interface Props {
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    onDelete: () => void;
    onUpdate: () => void;
}

export function WorkflowTemplatesManagement(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = React.useState(TabIndex.WORKFLOW_TEMPLATE);
    const [filterState, setFilterState] = React.useState<FilterState>({
        from: "",
        to: "",
    });

    const [subWorkflowFilterState, setSubWorkflowFilterState] = React.useState<FilterState>({
        from: "",
        to: "",
    });
    const handleTabSelect = (index: number, lastIndex: number) => {
        if (index !== lastIndex) {
            setSelectedTab(index as TabIndex);
        }
        return true;
    };
    return (
        <>
            <Tabs onSelect={handleTabSelect} className={style.tabs} selectedTabPanelClassName={style.selectedTab}>
                <TabList>
                    <Tab data-testid={testIds.workArea.workflowTemplatesManager.primaryView.tabs.templates}>
                        {t("workflowTemplatesManager.template.title")}
                    </Tab>
                    <Tab data-testid={testIds.workArea.workflowTemplatesManager.primaryView.tabs.subWorkflows}>
                        {t("workflowTemplatesManager.subWorkflows.title")}
                    </Tab>
                </TabList>
                <TabPanel>
                    {TabIndex.WORKFLOW_TEMPLATE === selectedTab && (
                        <>
                            {
                                <WorkflowTemplatesTable
                                    isSubWorkflow={false}
                                    search={""}
                                    count={props.count}
                                    setCount={props.setCount}
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    tableIdentity={RepositoryKey.WORKFLOW_TEMPLATES_TABLE}
                                    onDelete={props.onDelete}
                                    onUpdate={props.onUpdate}
                                />
                            }
                        </>
                    )}
                </TabPanel>
                <TabPanel>
                    {TabIndex.SUB_WORKFLOW === selectedTab && (
                        <>
                            {
                                <WorkflowTemplatesTable
                                    isSubWorkflow={true}
                                    filterState={subWorkflowFilterState}
                                    setFilterState={setSubWorkflowFilterState}
                                    count={props.count}
                                    setCount={props.setCount}
                                    search={""}
                                    tableIdentity={RepositoryKey.SUB_WORKFLOWS_TABLE}
                                    onDelete={props.onDelete}
                                    onUpdate={props.onUpdate}
                                />
                            }
                        </>
                    )}
                </TabPanel>
            </Tabs>
        </>
    );
}
