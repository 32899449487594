import { Buffer } from "buffer";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { Format } from "./ErasureReportModal";
import style from "./report-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { AUTH_REPORT_TEMPLATE_VIEW } from "domain/authority";
import { BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID, TemplateTableData } from "domain/reports";
import { apiGatewayService } from "services/api/ApiGatewayService";
import { getOliverUrl } from "services/login/endpointRepository";
import { replacePaths } from "services/report/erasure/ReportService";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import form from "styles/form.scss";
import { logger } from "utils/logging";

export const deriveReportUrl = (
    uuid?: string,
    language?: string,
    format?: string,
    templateUuid?: string | null,
    columns?: string[]
): string => {
    const urlPath = "/api/reports/" + uuid + "?language=" + language + "&format=" + format;
    if (format === Format.XML) {
        return "/api/reports/" + uuid + "?format=" + format;
    }
    if (templateUuid === BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID) {
        return urlPath;
    }
    if (templateUuid != null && typeof templateUuid != "undefined" && templateUuid?.trim() !== "") {
        return urlPath + "&templateUuid=" + templateUuid;
    }
    if (format === Format.SUMMARY_CSV || format === Format.SUMMARY_PDF) {
        const jsonString = JSON.stringify(replacePaths(columns, format));
        const encodedJsonString = Buffer.from(jsonString).toString("base64");
        return urlPath + "&columns=" + encodedJsonString;
    } else {
        return urlPath;
    }
};

const connector = connect((state: StoreState) => ({
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
}));

const ReportView = (
    props: {
        title: string;
        reportUuid: string;
        languageCode: string;
        onFetchTemplates: (templates: TemplateTableData[]) => void;
        templateUuid?: string;
        onCustomizationEnabled: (enabled: boolean) => void;
        print?: boolean;
        setPrint?: (print: boolean) => void;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(true);
    const loadingIndicator = loading ? <LoadingIndicator /> : null;
    const iframe = React.useRef(null);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [failureResult, setFailureResult] = React.useState({
        text: "",
        failed: false,
    });
    const fetchTemplates = (): void => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoading(true);
        reportTemplateService
            .fetchTemplates(abortController)
            .then((data) => {
                props.onFetchTemplates(data);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setFailureResult({
                        text: t(""),
                        failed: true,
                    });
                }
            })
            .finally(() => setLoading(false));
    };
    const renderReport = (templateUuid?: string) => {
        setLoading(true);
        const url = getOliverUrl() + deriveReportUrl(props.reportUuid, props.languageCode, "HTML", templateUuid);
        apiGatewayService
            .fetch(url, { mode: "cors", credentials: "include" })
            .then((res) => res.text())
            .then((body) => {
                const current = iframe.current;
                if (current == null) {
                    return;
                }
                const iframeDocument = (current as HTMLIFrameElement).contentDocument;
                if (iframeDocument == null) {
                    return;
                }
                iframeDocument.write(body);
                iframeDocument.close();
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (props.templateUuid) {
            renderReport(props.templateUuid);
        } else {
            renderReport();
        }
    }, [props.templateUuid]);

    React.useEffect(() => {
        if (
            props.tenantDetails?.featureLicenses.includes("FEATURE_REPORT_CUSTOMIZATION") &&
            userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_VIEW])
        ) {
            props.onCustomizationEnabled(true);
            fetchTemplates();
        }
        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, []);

    React.useEffect(() => {
        if (props.print) {
            const current = iframe.current;
            if (current == null) {
                logger.error("Unable to load report for printing.");
                return;
            }
            const iframeWindow = (current as HTMLIFrameElement).contentWindow;
            const iframeDocument = (current as HTMLIFrameElement).contentDocument;

            // Add print styles
            if (iframeDocument?.head) {
                const style = iframeDocument.createElement("style");
                style.textContent = `
                    @page {
                        size: A4;
                        margin-top: 90px;
                        margin-bottom: 90px;
                    }
                `;
                iframeDocument.head.appendChild(style);
            }

            iframeWindow?.focus();
            iframeWindow?.print();
            props.setPrint && props.setPrint(false);
        }
    }, [props.print]);
    return (
        <>
            <div>
                <div className={style.loadContainer}>{loadingIndicator}</div>
                <div hidden={loading}>
                    {failureResult.failed ? (
                        <div className={form.resultContainer}>{failureResult.text}</div>
                    ) : (
                        <iframe
                            ref={iframe}
                            title={props.title}
                            src={"about:blank"}
                            className={style.reportContainer}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default connector(ReportView);
