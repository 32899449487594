import classNames from "classnames";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import style from "./add-license-delivery.scss";
import Warning from "components/icons/Warning";
import DeliveryFormContent, {
    createDefaultSelectedLicense,
    DeliveryForm,
    isConfirmationDialogVisible,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/DeliveryFormContent";
import LicenseSelectionForm from "components/licenses/delivery-history/LicenseSelectionForm";
import { isAnyLicenseInvalid } from "components/tenants/DeliveryTab";
import { LicenseToDeliver } from "domain/licenses";
import { CombinedTier, LicensingModel, TenantType } from "domain/tenants";
import { extractLatestLicenseRateUuid, ProductToRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { isUserParentInternal } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyNumbers } from "utils/commonFunctions";
import {
    formatDateWithoutTime,
    formatExpirationDate,
    formatUtcDateString,
    HOUR,
    MINUTES_SECONDS_MILLISECONDS,
} from "utils/format";

import testIds from "testIds.json";

const DEFAULT_SELECT_DELIVERY_TYPE = "delivery_type";

interface Props {
    submitEventHandler: (values: DeliveryForm, selectedLicenses: LicenseToDeliver[]) => Promise<void>;
    availableLicenses: License[];
    ownedLicenses: License[];
    preselectedFromBundle: boolean;
    licensingModel: LicensingModel;
    rateVersions: ProductToRateList[];
    tenantTier: CombinedTier;
    tenantType: TenantType;
    parentDrawbackPrevention: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const AddLicenseDeliveryForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [selectedLicenses, setSelectedLicenses] = React.useState<License[]>(
        props.ownedLicenses.length > 0 ? props.ownedLicenses : [createDefaultSelectedLicense(t)]
    );
    const [hiddenForm, setHiddenForm] = React.useState(false);
    const [formState, setFormState] = React.useState<DeliveryForm>();
    const [validateOnChange, setValidateOnChange] = React.useState(false);
    const [warningFlag, setWarningFlag] = React.useState<boolean>(false);
    const DEFAULT_SELECTION_VALUE = "DEFAULT";

    const isAddButtonDisabled = (errors: FormikErrors<DeliveryForm>, dirty: boolean, submit: number) => {
        let hasFormErrors = Object.keys(errors).length > 0;
        if (selectedLicenses.length >= 1) {
            selectedLicenses.forEach((item) => {
                if (item.licenseType === "default" && submit > 0) {
                    hasFormErrors = true;
                }
            });
        }
        return (hasFormErrors && dirty) || hasFormErrors;
    };

    const validateForm = (values: DeliveryForm) => {
        const validationErrors: string[] = [];
        const isValueRequired = isUserParentInternal();

        if (!values.caseNumber && isValueRequired) {
            validationErrors.push(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberRequired"));
        }

        if (!values.opportunityId && isValueRequired) {
            validationErrors.push(t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdRequired"));
        }

        if (selectedLicenses.length < 1 || isAnyLicenseInvalid(selectedLicenses)) {
            validationErrors.push(t("DeliveryHistory.addLicenseDelivery.validation.licenseInvalid"));
        }

        selectedLicenses.forEach((item) => {
            if (item.licenseType === "default") {
                validationErrors.push(
                    t("DeliveryHistory.addLicenseDelivery.validation.licenseInvalid", { licenseType: item.licenseType })
                );
            }
        });

        return validationErrors;
    };

    const createConfirmationDialog = () => {
        const createdLicenses: License[] = [];
        const removedLicenses: License[] = [];
        selectedLicenses.forEach((each) => {
            if (each.licensesToAdd >= 0) {
                createdLicenses.push(each);
            } else {
                removedLicenses.push(each);
            }
        });
        return (
            <div>
                {removedLicenses.length > 0 && (
                    <div className={form.resultContainer}>
                        {warningFlag && (
                            <div className={classNames(form.warningBanner, form.fixedWidthModal)}>
                                <div className={style.textAndBannerContainer}>
                                    <Warning color={theme.secondaryWarningBackgroundColor} width={40} height={20} />
                                    {t("Common.withdrawalWarning")}
                                </div>
                            </div>
                        )}
                        <div>
                            {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.removing")}
                            <ul>
                                {removedLicenses.map((each) => (
                                    <li key={each.licenseType}>
                                        {t("DeliveryHistory.addLicenseDelivery.license", {
                                            amount: each.licensesToAdd,
                                            productName: each.productName,
                                            expirationDate: formatDateWithoutTime(each.expirationDate),
                                        })}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {createdLicenses.length > 0 && (
                    <div className={form.resultContainer}>
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.adding")}
                        <ul>
                            {createdLicenses.map((each) => (
                                <li key={each.licenseType}>
                                    {t("DeliveryHistory.addLicenseDelivery.license", {
                                        amount: each.licensesToAdd,
                                        productName: each.productName,
                                        expirationDate: formatDateWithoutTime(each.expirationDate),
                                    })}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={form.resultContainer}>
                    {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirmation")}
                </div>
                <div className={buttons.buttonContainer}>
                    <button
                        type={"button"}
                        className={buttons.secondaryButtonWithoutIcon}
                        onClick={() => {
                            setHiddenForm(false);
                        }}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.keepEditing")}
                    </button>
                    <button
                        className={buttons.primaryButtonWithoutIcon}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                        onClick={async () => {
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_DELIVERY,
                                action: Action.ADD_LICENSE_DELIVERY,
                            });

                            const licensesToDeliver = selectedLicenses.map((each) => ({
                                licenseId: each.licenseType,
                                expirationDate: formatUtcDateString(
                                    formatExpirationDate(each.expirationDate),
                                    HOUR,
                                    MINUTES_SECONDS_MILLISECONDS,
                                    MINUTES_SECONDS_MILLISECONDS
                                ),
                                amount: each.licensesToAdd,
                                totalOfLicenses: each.totalOfLicenses,
                            }));
                            if (formState) {
                                await props.submitEventHandler(formState, licensesToDeliver);
                            }
                        }}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirm")}
                    </button>
                </div>
            </div>
        );
    };
    return (
        <Formik
            initialValues={{
                deliveryType: "",
                caseNumber: "",
                opportunityId: "",
                notes: "",
                amount: null,
                expirationDate: "",
                bundle: DEFAULT_SELECTION_VALUE,
                tenantType: DEFAULT_SELECTION_VALUE,
                tokenRateVersion: extractLatestLicenseRateUuid(props.rateVersions),
            }}
            onSubmit={async (values) => {
                const validationErrors = validateForm(values);
                if (validationErrors.length > 0) {
                    return;
                }
                const isConfirmationVisible = isConfirmationDialogVisible(
                    values.deliveryType,
                    values.opportunityId,
                    values.caseNumber
                );
                if (isConfirmationVisible) {
                    setFormState(values);
                    setHiddenForm(true);
                } else {
                    const licensesToDeliver = selectedLicenses.map((each) => ({
                        licenseId: each.licenseType,
                        expirationDate: formatUtcDateString(
                            formatExpirationDate(each.expirationDate),
                            HOUR,
                            MINUTES_SECONDS_MILLISECONDS,
                            MINUTES_SECONDS_MILLISECONDS
                        ),
                        amount: each.licensesToAdd,
                        totalOfLicenses: each.totalOfLicenses,
                    }));
                    await props.submitEventHandler(values, licensesToDeliver);
                }
            }}
            validationSchema={object().shape({
                deliveryType: string()
                    .required(t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"))
                    .test(
                        "Default option selected",
                        t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"),
                        (value) => value !== DEFAULT_SELECT_DELIVERY_TYPE
                    ),
                caseNumber: isUserParentInternal()
                    ? string()
                          .required(t("DeliveryHistory.addLicenseDelivery.validation.caseNumber"))
                          .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                          .max(
                              MAX_CASE_NUMBER_LENGTH,
                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                  length: MAX_CASE_NUMBER_LENGTH,
                              })
                          )
                          .test(
                              "Has only numbers",
                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"),
                              (value: string | undefined) => hasOnlyNumbers(value)
                          )
                    : string()
                          .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                          .max(
                              MAX_CASE_NUMBER_LENGTH,
                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                  length: MAX_CASE_NUMBER_LENGTH,
                              })
                          )
                          .test(
                              "Has only numbers",
                              t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"),
                              (value: string | undefined) => value === undefined || hasOnlyNumbers(value)
                          ),
                opportunityId: isUserParentInternal()
                    ? string()
                          .required(t("DeliveryHistory.addLicenseDelivery.validation.opportunityId"))
                          .max(
                              MAX_OPPORTUNITY_ID_LENGTH,
                              t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                  length: MAX_OPPORTUNITY_ID_LENGTH,
                              })
                          )
                    : string().max(
                          MAX_OPPORTUNITY_ID_LENGTH,
                          t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                              length: MAX_OPPORTUNITY_ID_LENGTH,
                          })
                      ),
            })}
            validateOnChange={validateOnChange}
            validateOnBlur={false}
        >
            {({
                values,
                errors,
                handleChange,
                validateOnChange,
                touched,
                dirty,
                submitCount,
            }: FormikProps<DeliveryForm>) => {
                return (
                    <Form>
                        {!hiddenForm ? (
                            <>
                                <DeliveryFormContent
                                    availableLicenses={props.availableLicenses}
                                    ownedLicenses={props.ownedLicenses}
                                    selectedLicenses={selectedLicenses}
                                    setSelectedLicenses={(licenses) => setSelectedLicenses(licenses)}
                                    inTenantCreation={false}
                                    preselectedFromBundle={props.preselectedFromBundle}
                                    deliveryForm={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    licensingModel={props.licensingModel}
                                    rateVersions={props.rateVersions}
                                    selectedTier={props.tenantTier}
                                    validateOnChange={validateOnChange}
                                    touched={touched}
                                    tenantType={props.tenantType}
                                />
                                <LicenseSelectionForm
                                    availableLicenses={props.availableLicenses}
                                    ownedLicenses={props.ownedLicenses}
                                    deliveryForm={values}
                                    selectedLicenses={selectedLicenses}
                                    setSelectedLicenses={(licenses) => setSelectedLicenses(licenses)}
                                    newDeal={false}
                                    parentDrawbackPrevention={props.parentDrawbackPrevention}
                                    setWarningFlag={setWarningFlag}
                                />

                                <div className={style.gridRows}>
                                    <button
                                        type="submit"
                                        className={buttons.primaryButtonWithoutIcon}
                                        disabled={isAddButtonDisabled(errors, dirty, submitCount)}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.addButton
                                        }
                                        onClick={() => {
                                            setValidateOnChange(true);

                                            const validationErrors = validateForm(values);
                                            if (validationErrors.length > 0) {
                                                return;
                                            }
                                            if (
                                                isConfirmationDialogVisible(
                                                    values.deliveryType,
                                                    values.opportunityId,
                                                    values.caseNumber
                                                )
                                            ) {
                                                setFormState(values);
                                                setHiddenForm(true);
                                            } else {
                                                setHiddenForm(false);
                                            }
                                        }}
                                    >
                                        {t("DeliveryHistory.addLicenseDelivery.addButton")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            createConfirmationDialog()
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(AddLicenseDeliveryForm);
