import * as React from "react";

import style from "./currentplan.scss";

const CurrentPlan = (): JSX.Element => {
    return (
        <div className={style.currentPlan}>
            <div>
                <div className={style.planLabel}>CURRENT PLAN</div>
                <div className={style.planValue}>Corporate: Enterprise</div>
            </div>
            <div className={style.bundlesLabel}>
                <button
                    onClick={() => {
                        /* TODO: Implement click handler to open all bundles */
                    }}
                >
                    <strong>View all Bundles</strong>
                </button>
            </div>
        </div>
    );
};

export default CurrentPlan;
