import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export interface RegistrationTokenDto {
    token: string;
}

export interface RegistrationToken {
    token: string;
}

function toRegistrationToken(dto: RegistrationTokenDto): RegistrationToken {
    return {
        token: dto.token,
    };
}

class HybridMcService {
    public createMcRegistrationToken(): Promise<RegistrationToken> {
        return apiGatewayService
            .invokeApi("/api/v1/mcs/register", "POST", null, {
                apiType: ApiType.OLIVER,
            })
            .then((dto: RegistrationTokenDto) => toRegistrationToken(dto));
    }
}

export const hybridMcService = new HybridMcService();
