import * as React from "react";

const LocationPointer = (props: { borderColor: string }): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.58325C10.2098 3.58325 8.4929 4.29441 7.22703 5.56028C5.96116 6.82615 5.25 8.54304 5.25 10.3333C5.25 12.9485 6.9493 15.4739 8.79446 17.4216C9.70171 18.3793 10.6115 19.163 11.2953 19.7075C11.574 19.9294 11.8142 20.1108 12 20.2472C12.1858 20.1108 12.426 19.9294 12.7047 19.7075C13.3885 19.163 14.2983 18.3793 15.2055 17.4216C17.0507 15.4739 18.75 12.9485 18.75 10.3333C18.75 8.54304 18.0388 6.82615 16.773 5.56028C15.5071 4.29441 13.7902 3.58325 12 3.58325ZM12 21.1666C11.584 21.7906 11.5838 21.7905 11.5836 21.7903L11.583 21.79L11.5813 21.7888L11.5759 21.7852L11.5571 21.7725C11.5411 21.7616 11.5181 21.7459 11.4887 21.7256C11.43 21.6849 11.3454 21.6255 11.2386 21.5484C11.0252 21.3942 10.7227 21.169 10.3609 20.8809C9.6385 20.3056 8.67329 19.4747 7.70554 18.4532C5.8007 16.4426 3.75 13.5514 3.75 10.3333C3.75 8.14522 4.61919 6.0468 6.16637 4.49962C7.71354 2.95245 9.81196 2.08325 12 2.08325C14.188 2.08325 16.2865 2.95245 17.8336 4.49962C19.3808 6.0468 20.25 8.14522 20.25 10.3333C20.25 13.5514 18.1993 16.4426 16.2945 18.4532C15.3267 19.4747 14.3615 20.3056 13.6391 20.8809C13.2773 21.169 12.9748 21.3942 12.7614 21.5484C12.6546 21.6255 12.57 21.6849 12.5113 21.7256C12.494 21.7375 12.4789 21.7479 12.4662 21.7566C12.4573 21.7627 12.4495 21.768 12.4429 21.7725L12.4241 21.7852L12.4187 21.7888L12.417 21.79L12.4164 21.7903C12.4162 21.7905 12.416 21.7906 12 21.1666ZM12 21.1666L12.416 21.7906C12.1641 21.9586 11.8359 21.9586 11.584 21.7906L12 21.1666ZM12 8.58325C11.0335 8.58325 10.25 9.36675 10.25 10.3333C10.25 11.2998 11.0335 12.0833 12 12.0833C12.9665 12.0833 13.75 11.2998 13.75 10.3333C13.75 9.36675 12.9665 8.58325 12 8.58325ZM8.75 10.3333C8.75 8.53833 10.2051 7.08325 12 7.08325C13.7949 7.08325 15.25 8.53833 15.25 10.3333C15.25 12.1282 13.7949 13.5833 12 13.5833C10.2051 13.5833 8.75 12.1282 8.75 10.3333Z"
                fill={props.borderColor}
            />
        </svg>
    );
};

export default LocationPointer;
