import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import BmsOptionsInputComponent from "../bms/form-components/BmsOptionsInputComponent";
import style from "./bms-config.scss";
import {
    BBTIConfiguration,
    IdentityVerificationConfiguration,
    InsuranceConfiguration,
    LeaseConfiguration,
    NtfConfiguration,
    ValidationConfiguration,
} from "./BmsCommonInterfaces";
import { FeatureTestsConfiguration, updateFeatureTests } from "./bmsUtils";
import Checkbox from "./form-components/BmsCheckboxComponent";
import RadioButton from "./form-components/BmsRadioButtonComponent";
import BmsSectionHeader from "./form-components/BmsSectionHeader";
import InputComponent from "./form-components/BmsTextInputComponent";
import defaultConfiguration from "./UiConfiguration.json";
import Warning from "components/icons/Warning";
import {
    BMS_LICENSE_FEATURE_ALL,
    BMS_LICENSE_FEATURE_GATHER_IMEI,
    BMS_LICENSE_FEATURE_LEASE,
} from "components/licenses/common";
import Modal from "components/modal/Modal";
import { StoreState } from "store";

interface FormValues {
    brand: {
        bms_server?: string;
        brandId?: string;
        appName?: string;
        image?: string;
        tag_line?: string;
        primaryColor?: string;
        secondaryColor?: string;
        tertiaryColor?: string;
        checkContrast?: boolean;
    };
    platform: string;
    marketing_name_url: string;
    features?: string[];
    componentFeatures: {
        [key: string]: {
            enabled: boolean;
            components: { name: string; enabled: boolean }[];
        };
    };
    bbtiConfiguration: BBTIConfiguration;
    validationConfiguration: ValidationConfiguration;
    insuranceConfiguration: InsuranceConfiguration;
    ntfConfiguration: NtfConfiguration;
    leaseConfiguration: LeaseConfiguration;
    identityVerificationConfiguration: IdentityVerificationConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
    featuresWithAvailableLicenses?: string[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const GenericConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const MAX_APP_NAME_LENGTH = 15;
    const { t } = useTranslation();
    const singleComponentSelectionFeatures = [BMS_LICENSE_FEATURE_GATHER_IMEI, BMS_LICENSE_FEATURE_LEASE];
    const enableFeatures = (
        defaultConfiguration: {
            [key: string]: {
                enabled: boolean;
                components: { name: string; enabled: boolean }[];
            };
        },
        features: string[] | undefined
    ) => {
        if (!features) {
            return defaultConfiguration;
        }

        const updatedConfiguration = { ...defaultConfiguration };
        features.forEach((feature) => {
            if (feature in updatedConfiguration) {
                updatedConfiguration[feature].enabled = true;
            }
        });
        return updatedConfiguration;
    };

    const [showPlatformChangeWarning, setShowPlatformChangeWarning] = React.useState(false);

    React.useEffect(() => {
        if (Object.keys(props.formValues.componentFeatures).length === 0) {
            props.setFormValues({
                ...props.formValues,
                componentFeatures: enableFeatures(defaultConfiguration.component_feature, props.formValues.features),
            });
        }
    }, [props.formValues.componentFeatures, props.setFormValues]);

    const platformType = [
        { value: "appUSDK", label: t("Configuration.genericConfigurationForm.appUSDK") },
        { value: "appISDK", label: t("Configuration.genericConfigurationForm.appISDK") },
        { value: "browser", label: t("Configuration.genericConfigurationForm.browser") },
    ];

    function getComplementaryColor(hexColor: string | undefined) {
        if (hexColor === undefined) {
            return props.theme.colorPickerBlack;
        }

        // Remove the hash character (#) if present
        hexColor = hexColor.replace("#", "");

        // Parse the hex color to RGB
        const r = parseInt(hexColor.substring(0, 2), 16);
        const g = parseInt(hexColor.substring(2, 4), 16);
        const b = parseInt(hexColor.substring(4, 6), 16);

        // Calculate complementary color by subtracting each RGB component from 255
        const compR = 255 - r;
        const compG = 255 - g;
        const compB = 255 - b;

        // Convert the components back to hex and concatenate
        return (
            "#" +
            compR.toString(16).padStart(2, "0") +
            compG.toString(16).padStart(2, "0") +
            compB.toString(16).padStart(2, "0")
        );
    }

    const handleInputChange = (fieldName: string, value: string) => {
        props.setFormValues({
            ...props.formValues,
            brand: {
                ...props.formValues.brand,
                [fieldName]: fieldName === "checkContrast" ? value === "true" : value,
            },
        });
    };

    const handleComponentChange = (featureKey: string, componentName: string | null, isChecked: boolean) => {
        props.setFormValues((prevFormValues) => {
            const updatedFormValues = { ...prevFormValues };
            const feature = updatedFormValues.componentFeatures[featureKey];

            if (feature) {
                if (featureKey === "FaultCheck" && isChecked) {
                    // If FaultCheck is enabled, also enable Recommendations
                    const recommendationsFeature = updatedFormValues.componentFeatures["Recommendations"];
                    if (recommendationsFeature) {
                        recommendationsFeature.enabled = true;
                    }
                }

                if (singleComponentSelectionFeatures.includes(featureKey) && componentName != null) {
                    feature.components.forEach((component) => {
                        if (component.name.toLowerCase() != componentName.toLowerCase()) {
                            component.enabled = false;
                        }
                    });
                }

                if (componentName === null) {
                    // If the feature checkbox is unchecked, uncheck all components
                    feature.enabled = isChecked;
                    if (!isChecked) {
                        feature.components.forEach((component) => {
                            component.enabled = false;
                        });
                    }
                } else {
                    const components = feature.components;

                    if (components) {
                        const component = components.find((c) => c.name === componentName);

                        if (component) {
                            component.enabled = isChecked;
                        }
                    }
                }
            }

            return updatedFormValues;
        });
    };

    const handlePlatformChange = (newValue: string) => {
        props.setFormValues({
            ...props.formValues,
            platform: newValue,
        });
        updateFeatureTests(props.formValues as FeatureTestsConfiguration, newValue);
        setShowPlatformChangeWarning(true);
    };

    const hidePlatformChangeWarning = () => {
        setShowPlatformChangeWarning(false);
    };

    const handleMarketingNameUrlChange = (newValue: string) => {
        props.setFormValues({
            ...props.formValues,
            marketing_name_url: newValue,
        });
    };

    const isDisabled = (feature: string) => {
        if (props.featuresWithAvailableLicenses) {
            if (props.featuresWithAvailableLicenses.includes(BMS_LICENSE_FEATURE_ALL)) {
                return false;
            } else {
                return !props.featuresWithAvailableLicenses.includes(feature);
            }
        }
        return true;
    };

    return (
        <div>
            <div className={classNames(style.boxedSection)}>
                <BmsSectionHeader
                    labelKey={t("Configuration.genericConfigurationForm.headers.brand")}
                    toolTipContent={t("Configuration.tooltips.genericConfigurationForm.brand")}
                />

                <div className={classNames(style.formSection)}>
                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.bms_server")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.bmsServer")}
                        inputId={"bms_server"}
                        value={props.formValues.brand.bms_server || ""}
                        onChange={(newValue) => handleInputChange("bms_server", newValue)}
                    />

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.brandId")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.brandId")}
                        inputId={"brandId"}
                        value={props.formValues.brand.brandId || ""}
                        onChange={(newValue) => handleInputChange("brandId", newValue)}
                    />

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.splashImage")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.splashImage")}
                        inputId={"image"}
                        value={props.formValues.brand.image || ""}
                        onChange={(newValue) => handleInputChange("image", newValue)}
                    />

                    {props.formValues.brand.image && (
                        <img
                            src={props.formValues.brand.image}
                            alt={t("AltText.logo")}
                            className={classNames(style.displayImage)}
                        />
                    )}

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.appName")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.appName", {
                            max_length: MAX_APP_NAME_LENGTH,
                        })}
                        inputId={"appName"}
                        value={props.formValues.brand.appName || ""}
                        onChange={(newValue) => handleInputChange("appName", newValue)}
                        maxLength={MAX_APP_NAME_LENGTH}
                    />

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.tagLine")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.tagLine")}
                        inputId={"tag_line"}
                        value={props.formValues.brand.tag_line || ""}
                        onChange={(newValue) => handleInputChange("tag_line", newValue)}
                    />

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.primaryColor")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.primaryColor")}
                        inputId={"primaryColor"}
                        value={props.formValues.brand.primaryColor || ""}
                        onChange={(newValue) => handleInputChange("primaryColor", newValue)}
                        inputStyle={{
                            backgroundColor: props.formValues?.brand?.primaryColor,
                            color: getComplementaryColor(props.formValues?.brand?.primaryColor as string),
                        }}
                    />

                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.secondaryColor")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.secondaryColor")}
                        inputId={"secondaryColor"}
                        value={props.formValues.brand.secondaryColor || ""}
                        onChange={(newValue) => handleInputChange("secondaryColor", newValue)}
                        inputStyle={{
                            backgroundColor: props.formValues?.brand?.secondaryColor,
                            color: getComplementaryColor(props.formValues?.brand?.secondaryColor as string),
                        }}
                    />
                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.tertiaryColor")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.tertiaryColor")}
                        inputId={"tertiaryColor"}
                        value={props.formValues.brand.tertiaryColor || ""}
                        onChange={(newValue) => handleInputChange("tertiaryColor", newValue)}
                        inputStyle={{
                            backgroundColor: props.formValues?.brand?.tertiaryColor,
                            color: getComplementaryColor(props.formValues?.brand?.tertiaryColor as string),
                        }}
                    />
                    <Checkbox
                        labelKey={t("Configuration.genericConfigurationForm.checkContrast")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.checkContrast")}
                        checkboxId={"checkContrast"}
                        checked={props.formValues.brand.checkContrast || false}
                        onChange={(newValue: boolean) => handleInputChange("checkContrast", newValue + "")}
                    />
                </div>
            </div>

            <div className={classNames(style.boxedSection)}>
                <BmsSectionHeader
                    labelKey={t("Configuration.genericConfigurationForm.headers.appConfiguration")}
                    toolTipContent={t("Configuration.tooltips.genericConfigurationForm.appConfiguration")}
                />
                <div className={classNames(style.formSection)}>
                    <InputComponent
                        labelKey={t("Configuration.genericConfigurationForm.marketingNameUrl")}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.marketingNameUrl")}
                        inputId={"marketing_name_url"}
                        value={props.formValues.marketing_name_url || ""}
                        onChange={(newValue) => handleMarketingNameUrlChange(newValue)}
                    />

                    <BmsOptionsInputComponent
                        labelKey={t("Configuration.genericConfigurationForm.platformType")}
                        value={props.formValues.platform}
                        toolTipContent={t("Configuration.tooltips.genericConfigurationForm.platformType")}
                        options={platformType}
                        onChange={handlePlatformChange}
                    />
                </div>
            </div>
            <div className={classNames(style.boxedSection)}>
                <BmsSectionHeader
                    labelKey={t("Configuration.genericConfigurationForm.headers.features")}
                    toolTipContent={t("Configuration.tooltips.genericConfigurationForm.featureSection")}
                />
                {Object.entries(props.formValues.componentFeatures).map(([featureKey, featureValue], index) => (
                    <div className={classNames(style.formSection, style.features)} key={featureKey}>
                        <>
                            <Checkbox
                                labelKey={t(
                                    `Configuration.genericConfigurationForm.features.${featureKey.toLowerCase()}`
                                )}
                                toolTipContent={t(
                                    `Configuration.tooltips.genericConfigurationForm.features.${featureKey.toLowerCase()}`
                                )}
                                checkboxId={`feature-checkbox-${index}`}
                                checked={featureValue.enabled}
                                disabled={isDisabled(featureKey)}
                                name={featureKey}
                                onChange={(newValue: boolean) => handleComponentChange(featureKey, null, newValue)}
                            />
                            {featureValue.enabled && (
                                <>
                                    {featureValue.components.map((component, componentIndex) => (
                                        <div
                                            id={"feature"}
                                            className={classNames(style.components)}
                                            key={`${featureKey}-${componentIndex}`}
                                        >
                                            {singleComponentSelectionFeatures.includes(featureKey) ? (
                                                <RadioButton
                                                    labelKey={t(
                                                        `Configuration.genericConfigurationForm.features.components.${component.name.toLowerCase()}`
                                                    )}
                                                    toolTipContent={t(
                                                        `Configuration.tooltips.genericConfigurationForm.features.components.${component.name.toLowerCase()}`
                                                    )}
                                                    name={component.name}
                                                    radioId={`component-radio-${index}-${componentIndex}-${component.name}`}
                                                    checked={component.enabled}
                                                    onChange={(newValue: boolean) =>
                                                        handleComponentChange(featureKey, component.name, newValue)
                                                    }
                                                />
                                            ) : (
                                                <Checkbox
                                                    labelKey={t(
                                                        `Configuration.genericConfigurationForm.features.components.${component.name.toLowerCase()}`
                                                    )}
                                                    toolTipContent={t(
                                                        `Configuration.tooltips.genericConfigurationForm.features.components.${component.name.toLowerCase()}`
                                                    )}
                                                    checkboxId={`component-checkbox-${index}-${componentIndex}`}
                                                    checked={component.enabled}
                                                    name={component.name}
                                                    onChange={(newValue: boolean) =>
                                                        handleComponentChange(featureKey, component.name, newValue)
                                                    }
                                                />
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                ))}
            </div>

            <Modal
                isOpen={showPlatformChangeWarning}
                hideModal={hidePlatformChangeWarning}
                modalTitle={t("Configuration.genericConfigurationForm.headers.platformChangeModal")}
            >
                <div>
                    <Warning color={props.theme.warningBackgroundColor} strokeColor={"#FFF"} width={20} height={20} />
                    <span className={classNames(style.components)}>
                        {t("Configuration.genericConfigurationForm.platformChangeWarning")}
                    </span>
                </div>
            </Modal>
        </div>
    );
};

export default connector(GenericConfigurationForm);
