import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./productcard.scss";
import buttons from "styles/buttons.scss";

import wire from "assets/images/icons/productIcons/wire.svg";

import testIds from "testIds.json";

const ProductCard = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={style.productCard}>
            <div className={style.productCardWrapper}>
                <div>
                    <div className={style.name}>Blancco Drive Eraser</div>
                    <div className={style.version}>
                        <img src={wire} alt="" />
                        <span>7.15</span>
                    </div>
                </div>
                <div>
                    <button
                        className={buttons.primaryButtonWithoutIcon}
                        data-testid={testIds.homePage.getStarted.button}
                        onClick={() => {
                            /* TODO: Implement click handler to show get started BDE component */
                        }}
                    >
                        {t("Onboarding.home.getStartedButton")}
                    </button>
                </div>
            </div>
            <div className={style.description}>
                <span>
                    Ensure compliant data erasure for HDDs, SSDs, and NVMes across diverse devices, from loose drives to
                    laptops based on x86 chip.
                </span>
            </div>
        </div>
    );
};

export default ProductCard;
