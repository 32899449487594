// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Pypf_7boYTUam5YWOWZ08{box-sizing:border-box;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;padding:12px;gap:12px;width:445px;background:#fafafa;border:1px solid #f7f7f7;border-radius:8px}.nau4oyuoSrwphbbGAczgv{display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%}._39PRxpqoIP3hS37gzMzDjx{font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#303030}._3qUAHLrQpRUJxjrSL_xb_b{font-style:normal;font-weight:500;font-size:12px;line-height:18px;color:#606060}._3rqCX37OAP8XAhVZ__Oo5y{font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#606060;display:flex;align-items:center}._3rqCX37OAP8XAhVZ__Oo5y img{margin-right:14px}", ""]);
// Exports
exports.locals = {
	"productCard": "_1Pypf_7boYTUam5YWOWZ08",
	"productCardWrapper": "nau4oyuoSrwphbbGAczgv",
	"name": "_39PRxpqoIP3hS37gzMzDjx",
	"description": "_3qUAHLrQpRUJxjrSL_xb_b",
	"version": "_3rqCX37OAP8XAhVZ__Oo5y"
};
module.exports = exports;
