import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./confirmation-modal.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import Modal from "components/modal/Modal";
import { WorkflowEntityType } from "domain/workflows";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

export interface Props {
    confirmCancelVisible?: boolean;
    setShowConfirmCancelDialog?: (value: boolean) => void;
    onCancel: (value: boolean) => void;
    onUpdateParentView: () => void;
    editorOpenTabs?: string;
    confirmVisibleRefreshCount?: number;
    entityType?: WorkflowEntityType;
    setHasNewEditorLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Tab {
    name: string;
    saved: boolean;
}

interface Tabs {
    tabs: Tab[];
}

interface OpenTabsDetailsDialog {
    title: string;
    message: JSX.Element;
    buttons: JSX.Element;
}

const ConfirmationModal = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [openTabsDetailsDialog, setOpenTabsDetailsDialog] = React.useState<OpenTabsDetailsDialog>();
    const [showModal, setShowModal] = React.useState(false);

    const closeTheEditor = () => {
        props.onCancel(true);
        props.setHasNewEditorLoaded && props.setHasNewEditorLoaded(false);
        props.onUpdateParentView();
        return;
    };

    React.useEffect(() => {
        if (props.confirmVisibleRefreshCount) {
            setOpenTabsDetailsDialog({
                title: "",
                message: <></>,
                buttons: <></>,
            });
            const tabsData = props.editorOpenTabs;
            if (tabsData) {
                const openTabsDetails: Tabs = { tabs: JSON.parse(tabsData) };
                if (openTabsDetails.tabs.length === 0) {
                    closeTheEditor();
                } else {
                    const unsavedTabs = openTabsDetails.tabs.filter((tab) => tab.saved === false);
                    const savedTabs = openTabsDetails.tabs.filter((tab) => tab.saved === true);

                    const openTabsCount = openTabsDetails.tabs.length;
                    const savedTabsCount = savedTabs.length;

                    if (openTabsCount === 1 && savedTabsCount === openTabsCount) {
                        closeTheEditor();
                    } else if (openTabsCount === savedTabsCount) {
                        setOpenTabsDetailsDialog({
                            title: t("ManageWorkflowDialog.confirmCancelEditingDialog.multipleSavedTabsDetails.title", {
                                count: savedTabsCount,
                            }),
                            message: (
                                <>
                                    {t(
                                        "ManageWorkflowDialog.confirmCancelEditingDialog.multipleSavedTabsDetails.message"
                                    )}
                                </>
                            ),
                            buttons: (
                                <ButtonContainer>
                                    <Button
                                        variant={"PRIMARY"}
                                        onClick={() => {
                                            props.onCancel(true);
                                            props.setHasNewEditorLoaded && props.setHasNewEditorLoaded(false);
                                            props.onUpdateParentView();
                                        }}
                                        testId={testIds.common.confirmationDialog.confirmButton}
                                    >
                                        {t(
                                            "ManageWorkflowDialog.confirmCancelEditingDialog.multipleSavedTabsDetails.closeAndLeaveButton"
                                        )}
                                    </Button>

                                    <Button
                                        variant={"SECONDARY"}
                                        onClick={() => {
                                            props.onCancel(false);
                                            props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                                        }}
                                        testId={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </Button>
                                </ButtonContainer>
                            ),
                        });
                    } else {
                        setOpenTabsDetailsDialog({
                            title:
                                unsavedTabs.length === 1
                                    ? t(
                                          "ManageWorkflowDialog.confirmCancelEditingDialog.singleUnsavedTabsDetails.title"
                                      )
                                    : t(
                                          "ManageWorkflowDialog.confirmCancelEditingDialog.multipleUnsavedTabsDetails.title",
                                          {
                                              count: unsavedTabs.length,
                                          }
                                      ),
                            message: (
                                <div className={style.unsavedTabsDetails}>
                                    {unsavedTabs.length === 1
                                        ? t(
                                              "ManageWorkflowDialog.confirmCancelEditingDialog.singleUnsavedTabsDetails.message"
                                          )
                                        : props.entityType === "WORKFLOW"
                                        ? t(
                                              "ManageWorkflowDialog.confirmCancelEditingDialog.multipleUnsavedTabsDetails.workflowsMessage"
                                          )
                                        : t(
                                              "ManageWorkflowDialog.confirmCancelEditingDialog.multipleUnsavedTabsDetails.templatesMessage"
                                          )}
                                    {unsavedTabs.length > 1 && (
                                        <ul>
                                            {unsavedTabs.map((each, index) => (
                                                <li key={index}>{each.name}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ),
                            buttons: (
                                <ButtonContainer>
                                    <Button
                                        variant={"PRIMARY"}
                                        onClick={() => {
                                            props.onCancel(false);
                                            props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                                        }}
                                        testId={testIds.common.dialog.closeButton}
                                    >
                                        {t("ManageWorkflowDialog.confirmCancelEditingDialog.keepEditingButton")}
                                    </Button>

                                    <Button
                                        variant={"SECONDARY"}
                                        onClick={() => {
                                            props.onCancel(true);
                                            props.setHasNewEditorLoaded && props.setHasNewEditorLoaded(false);
                                            props.onUpdateParentView();
                                        }}
                                        testId={testIds.common.confirmationDialog.confirmButton}
                                    >
                                        {t(
                                            "ManageWorkflowDialog.confirmCancelEditingDialog.singleUnsavedTabsDetails.closeDontSaveButton"
                                        )}
                                    </Button>
                                </ButtonContainer>
                            ),
                        });
                    }
                    setShowModal(true);
                }
            }
        }
    }, [props.confirmVisibleRefreshCount, props.editorOpenTabs]);

    return !props.editorOpenTabs ? (
        <Modal
            isOpen={props.confirmCancelVisible ?? false}
            hideModal={() => {
                props.onCancel(false);
                props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
            }}
            modalTitle={t("ManageWorkflowDialog.confirmCancelEditingDialog.title")}
        >
            <p className={style.cancelEditMessage}>
                {t("ManageWorkflowDialog.confirmCancelEditingDialog.confirmationText")}
            </p>
            <div className={style.buttonContainer}>
                <button
                    className={buttons.deleteButtonWithoutIcon}
                    onClick={() => {
                        props.onCancel(true);
                        props.onUpdateParentView();
                    }}
                    data-testid={testIds.common.confirmationDialog.confirmButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.closeDontSaveButton")}
                </button>
                <button
                    className={buttons.primaryButtonWithoutIcon}
                    onClick={() => {
                        props.onCancel(false);
                        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                    }}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.keepEditingButton")}
                </button>
            </div>
        </Modal>
    ) : showModal ? (
        <Modal
            isOpen={props.confirmCancelVisible ?? false}
            hideModal={() => {
                props.onCancel(false);
                props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
            }}
            modalTitle={openTabsDetailsDialog?.title}
        >
            <>
                <div className={style.cancelEditMessage}>{openTabsDetailsDialog?.message}</div>
                {openTabsDetailsDialog?.buttons}
            </>
        </Modal>
    ) : (
        <></>
    );
};

ConfirmationModal.defaultProps = {
    entityType: "WORKFLOW",
};

export default ConfirmationModal;
