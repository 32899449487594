import * as React from "react";
import { useTranslation } from "react-i18next";

import ManageWorkflowTemplateDialog from "./ManageWorkflowTemplateDialog";
import FlexibleModal from "components/modal/FlexibleModal";
import { WorkflowEntityType } from "domain/workflows";
import { WorkflowService } from "services/workflows/WorkflowService";

interface Props {
    entityType: WorkflowEntityType;
    modalTitle: string;
    modalVisibility: boolean;
    onShowModal: (doShow: boolean) => void;
    onUpdate: () => void;
    workflowService: WorkflowService;
    isCreate?: boolean;
    isEdit?: boolean;
    isUpdate?: boolean;
    name?: string;
    version?: string;
    product?: string;
    uuid?: string;
}

const ManageWorkflowTemplateWrapper: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [confirmCancelVisible, setConfirmCancelVisible] = React.useState(false);
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const [dynamicSize, setDynamicSize] = React.useState(true);
    const [fullscreen, setFullscreen] = React.useState<boolean>(false);
    const [hasNewEditorLoaded, setHasNewEditorLoaded] = React.useState<boolean>(false);

    const manageDynamicSize = (initial: boolean) => {
        setDynamicSize(initial);
    };

    const hideManageDialog = (doCancel: boolean) => {
        if (doCancel) {
            props.onShowModal(false);
        }
        setConfirmCancelVisible(false);
        setConfirmCancel(false);
    };
    const hideModal = confirmCancel
        ? () => setConfirmCancelVisible(true)
        : () => {
              hideManageDialog(true);
              props.onUpdate();
          };
    const onToggleFullscreen = () => {
        return;
    };

    React.useEffect(() => {
        props.modalVisibility && setDynamicSize(true);
        setFullscreen(!!props.isEdit);
    }, [props.modalVisibility]);

    return (
        <>
            <FlexibleModal
                key={1}
                isOpen={props.modalVisibility}
                hideModal={hideModal}
                title={t(props.modalTitle)}
                onToggleFullscreen={onToggleFullscreen}
                dynamicSize={dynamicSize}
                fullScreen={fullscreen}
                showHeader={!fullscreen}
                hasNewEditorLoaded={hasNewEditorLoaded}
            >
                <ManageWorkflowTemplateDialog
                    confirmCancelVisible={confirmCancelVisible}
                    entityType={props.entityType}
                    isCreate={props.isCreate}
                    onCancel={hideManageDialog}
                    onUpdateParentView={props.onUpdate}
                    setDynamicSize={manageDynamicSize}
                    setShowConfirmCancelDialog={setConfirmCancel}
                    workflowService={props.workflowService}
                    setFullscreen={setFullscreen}
                    name={props.name || undefined}
                    product={props.product || undefined}
                    version={props.version || undefined}
                    uuid={props.uuid || undefined}
                    isEdit={props.isEdit}
                    isUpdate={props.isUpdate}
                    setHasNewEditorLoaded={setHasNewEditorLoaded}
                />
            </FlexibleModal>
        </>
    );
};

export default ManageWorkflowTemplateWrapper;
