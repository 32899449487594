import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Column } from "react-table";

import style from "./diagnostic-summary-view.scss";
import Info from "components/icons/Info";
import { Question } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import { selectLanguageForConditionalQuestions } from "components/licenses/license-configuration/bms/bmsUtils";
import questionsData from "components/licenses/license-configuration/bms/UiConfiguration.json";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TOOLTIP_DELAY } from "domain/globalConstants";
import { Configuration } from "domain/licenses";
import {
    ConditionalQuestions,
    ConditionalQuestionsResults,
    DiagnosticReportResponse,
    DiagnosticSummary,
    TestResult,
} from "domain/reports";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";
import layoutStyle from "styles/layout.scss";
import { formatTimestamp } from "utils/format";
import { RepositoryKey } from "utils/repository";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";
import indeterminateIcon from "assets/images/icons/indeterminate.svg";
import skippedIcon from "assets/images/icons/skipped.svg";

import testIds from "testIds.json";

type QuestionsData = {
    [key: string]: Question[];
};

const deriveFeatureQuestions = async (configuration: Configuration, feature: string): Promise<QuestionsData> => {
    switch (feature) {
        case "bbti":
            return configuration.bbtiConfiguration.providers[0].questions as QuestionsData;
        case "ntf":
            return configuration.ntfConfiguration.providers[0].questions as QuestionsData;
        case "lease":
            return configuration.leaseConfiguration.provider.questions as QuestionsData;
        case "insurance":
            return configuration.insuranceConfiguration.provider.questions as QuestionsData;
        case "validation":
            return configuration.validationConfiguration.provider.questions as QuestionsData;
        default:
            return questionsData.questions as QuestionsData;
    }
};

export function DiagnosticSummaryView(props: {
    diagnosticReportResponse: DiagnosticReportResponse;
    clientConfiguration: Configuration;
    feature: string;
}): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const selectedLanguage = selectLanguageForConditionalQuestions(LanguageRepository.getLanguage().locale);

    const diagnosticData = useQuery<DiagnosticReportResponse, Error>(
        ["diagnosticSummary", props.diagnosticReportResponse],
        () => props.diagnosticReportResponse
    );

    const defaultFeatureQuestions = useQuery<QuestionsData, Error>(
        ["conditionalQuestions", props.feature],
        async () => {
            return await deriveFeatureQuestions(props.clientConfiguration, props.feature);
        },
        { enabled: !!props.diagnosticReportResponse }
    );

    const getIcon = (result: string): JSX.Element => {
        const icons = new Map<string, string>([
            [TestResult.PASSED, checkIcon],
            [TestResult.FAILED, crossIcon],
            [TestResult.SKIPPED, skippedIcon],
            [TestResult.INDETERMINATE, indeterminateIcon],
        ]);
        const icon = icons.get(result);
        return icon ? (
            <img
                src={icon}
                data-tip={t("DiagnosticReportsTable.diagnosticSummary." + result.toLowerCase())}
                data-delay-show={TOOLTIP_DELAY}
                alt={t("AltText.diagnosticSummary." + result.toLowerCase())}
            />
        ) : (
            <></>
        );
    };

    const getReasonCodeDescription = (reasonCode: string) => {
        const { t } = useTranslation("diagnosticReasonCodes");
        const translationKey = "reasonCodes." + reasonCode;
        const defaultTranslationKey = "reasonCodes.unknown";

        const translatedDescription = t(translationKey);
        const defaultDescription = t(defaultTranslationKey);

        return translatedDescription !== translationKey ? translatedDescription : defaultDescription;
    };

    const getInfo = (row: DiagnosticSummary): JSX.Element => {
        const totalAttempts = row.pass + row.fail + row.skip + row.indeterminate;
        const reasonCodeText =
            row.reasonCode && (row.latestResult == TestResult.FAILED || row.latestResult == TestResult.INDETERMINATE)
                ? t("DiagnosticReportsTable.diagnosticSummary.infoTooltipWithReasonCode", {
                      result: row.latestResult,
                      reasonCode: row.reasonCode,
                      reasonCodeDescription: getReasonCodeDescription(row.reasonCode),
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  })
                : t("DiagnosticReportsTable.diagnosticSummary.infoTooltip", {
                      result: row.latestResult,
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  });

        return (
            <div className={style.info}>
                <TextWithTooltip text={reasonCodeText} focusable={true}>
                    <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                </TextWithTooltip>
            </div>
        );
    };

    const conditionalQuestionsColumns: Array<Column<ConditionalQuestionsResults>> = [
        {
            accessor: "question",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(70, tableContainerRef),
        },
        {
            accessor: "answer",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(30, tableContainerRef),
        },
    ];

    const columns: Array<Column<DiagnosticSummary>> = [
        {
            Header: [<TextWithTooltip text={t("DiagnosticReportsTable.diagnosticSummary.identifier")} key="1" />],
            accessor: "test",
            Cell: ({ row }) => {
                return (
                    <div className={style.identifer}>
                        <div className={style.icon}>{getIcon(row.original.latestResult)}</div>
                        <div className={style.title}>
                            <TextWithTooltip text={row.original.test} />
                        </div>
                    </div>
                );
            },
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.passed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.passed")}
                        </div>
                    }
                    key="2"
                />,
            ],
            accessor: "pass",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.failed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.failed")}
                        </div>
                    }
                    key="3"
                />,
            ],
            accessor: "fail",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                        </div>
                    }
                    key="4"
                />,
            ],
            accessor: "skip",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "indeterminate",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.info")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.info")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "latestResult",
            Cell: ({ row }) => getInfo(row.original),
            width: deriveColumnWidth(10, tableContainerRef),
        },
    ];

    function processConditionalQuestionsResults(conditionalQuestions: ConditionalQuestions[]) {
        const questionMap: Map<string, { answer: string; reportDate: string | Date }> = new Map();

        if (conditionalQuestions) {
            const sortedQuestions = conditionalQuestions.sort(
                (a, b) => new Date(b.report_date).getTime() - new Date(a.report_date).getTime()
            );

            sortedQuestions.forEach((entry) => {
                entry.results.forEach((result) => {
                    const { question, answer } = result;
                    const reportDate = new Date(entry.report_date);

                    const selectedQuestionWithLanguage =
                        defaultFeatureQuestions.data == null
                            ? undefined
                            : defaultFeatureQuestions.data[selectedLanguage].find(
                                  (questionForLanguage) => questionForLanguage.id === question
                              );

                    const questionLabel = selectedQuestionWithLanguage?.label || question;
                    const selectedAnswerWithLanguage = selectedQuestionWithLanguage?.answers.find(
                        (answerForLanguage) => answerForLanguage.id === answer
                    );

                    const answerLabel = selectedAnswerWithLanguage?.label || answer;

                    const existingEntry = questionMap.get(questionLabel);
                    if (!existingEntry || reportDate > new Date(existingEntry.reportDate)) {
                        questionMap.set(questionLabel, {
                            answer: answerLabel,
                            reportDate: entry.report_date,
                        });
                    }
                });
            });
        }

        return Array.from(questionMap, ([question, { answer }]) => ({ question, answer }));
    }

    return (
        <div>
            <div ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.DIAGNOSTIC_SUMMARY_TABLE}
                    data={diagnosticData.data?.diagnosticSummary || []}
                    columns={columns}
                    failureMessage={
                        diagnosticData.error ? t("DiagnosticReportsTable.diagnosticSummary.requestFailed") : ""
                    }
                    loaded={!diagnosticData.isLoading}
                    tooltips={true}
                    emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
                    testId={testIds.workArea.report.erasure.table}
                />
            </div>

            <div className={classNames(style.conditionalQuestionsTable, layoutStyle.tableWrapper)}>
                <h2>
                    <b>Conditional Questions</b>
                </h2>
                <Table
                    tableIdentity={RepositoryKey.CONDITIONAL_SUMMARY_TABLE}
                    data={processConditionalQuestionsResults(
                        props.diagnosticReportResponse?.conditionalQuestions || []
                    )}
                    columns={conditionalQuestionsColumns}
                    failureMessage={
                        defaultFeatureQuestions.error ? t("DiagnosticReportsTable.diagnosticSummary.requestFailed") : ""
                    }
                    loaded={!defaultFeatureQuestions.isLoading}
                    tooltips={true}
                    header={false}
                    inDialogBox={true}
                    dialogHeight={350}
                    emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
                    noAction={true}
                />
            </div>
        </div>
    );
}

DiagnosticSummaryView.defaultProps = {
    initialSummaryData: [],
};
