import { useFeature } from "flagged";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TenantsTable } from "./TenantsTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import ManageLicenseAlertView from "components/licenses/license-alerts/manage-license-alerts/ManageLicenseAlertView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { ErrorModal } from "components/login/ErrorModal";
import { TENANTS_ROUTE } from "components/router/Routes";
import TenantCreationAction from "components/tenants/add-tenant/TenantCreationAction";
import { AUTH_TENANT_CREATE } from "domain/authority";
import { LicenseData } from "domain/licenses";
import { FLAG_TENANT_DELETE } from "services/feature/FeatureFlagService";
import { licenseService, ProductToRateList } from "services/licenses/LicenseService";
import { deriveTenantUuid } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { userSessionService } from "services/user/UserSessionService";
import { toDate } from "utils/commonFunctions";

type FetchState = "PENDING" | "FETCHED" | "FAILED";

export default function AllTenantsView(): JSX.Element {
    const { t } = useTranslation();

    const [state, setState] = useState({ count: 0 });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tenantLicenses, setTenantLicenses] = React.useState<LicenseData[] | undefined>();
    const [tenantLevel, setTenantLevel] = React.useState(0);
    const [tenantExpirationDate, setTenantExpirationDate] = React.useState<Date | undefined>();
    const [fetched, setFetched] = React.useState<FetchState>("PENDING");
    const [errorModalVisible, setErrorModalVisible] = React.useState(false);
    const [refreshingCount, setRefreshingCount] = React.useState(0);
    const [alertsViewVisibility, setAlertsViewVisibility] = React.useState<boolean>(false);
    const [parentDrawBackPrevention, setParentDrawBackPrevention] = React.useState<boolean>(false);
    const [tenantUuid, setTenantUuid] = React.useState<string>("");
    const [tenantName, setTenantName] = React.useState<string>("");
    const TENANT_MAX_LEVEL = 5;
    const [rateVersions, setRateVersions] = React.useState<ProductToRateList[]>([]);

    function onRefreshing() {
        setRefreshingCount((prevState) => prevState + 1);
    }

    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    async function fetchLicenseRateVersion(): Promise<ProductToRateList[]> {
        const abortController = new AbortController();
        const rateVersions: ProductToRateList[] = [];
        const fetchedTokenRates = await licenseService.fetchTokenRates(abortController);
        fetchedTokenRates.forEach((tokenRate) => {
            rateVersions.push(tokenRate);
        });
        return rateVersions;
    }

    const fetchTenantData = async () => {
        const expirationDateAbortController = new AbortController();
        abortControllers.push(expirationDateAbortController);
        const tenantPromise = tenantService.fetchTenant(deriveTenantUuid(), expirationDateAbortController);

        const licensesAbortController = new AbortController();
        abortControllers.push(licensesAbortController);
        const licensePromise = licenseService.fetchLicenses(licensesAbortController);
        await Promise.all([tenantPromise, licensePromise])
            .then(([tenant, licenses]) => {
                // Typing for tenant.expirationDate says it can't be null or undefined but it comes from the backend
                // so we're prepared for a non-string.
                const tenantExpiration = toDate(tenant.expirationDate);
                if (tenantExpiration != null) {
                    setTenantExpirationDate(tenantExpiration);
                }
                setParentDrawBackPrevention(tenant.parentDrawbackPrevention);
                setTenantLevel(tenant.tenantHierarchyLevel);
                setTenantLicenses(licenses.licenses);
                setFetched("FETCHED");
            })
            .catch(() => {
                setFetched("FAILED");
                setErrorModalVisible(true);
            });
    };

    React.useEffect(() => {
        fetchTenantData().then(() =>
            abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort())
        );
        fetchLicenseRateVersion().then((fetchedRateVersions) => {
            setRateVersions(fetchedRateVersions);
        });
    }, []);

    function createViewActions(): JSX.Element | undefined {
        if (
            !userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") ||
            !userSessionService.userHasAllAuthorities([AUTH_TENANT_CREATE])
        ) {
            return undefined;
        }
        if (fetched === "FETCHED" && tenantLevel < TENANT_MAX_LEVEL) {
            return (
                <TenantCreationAction
                    updateState={() => updateState()}
                    tenantExpirationDate={tenantExpirationDate}
                    tenantLevel={tenantLevel}
                    tenantLicenses={tenantLicenses}
                    rateVersions={rateVersions}
                    tenantUuid={deriveTenantUuid()}
                    parentDrawbackPrevention={parentDrawBackPrevention}
                />
            );
        }
        if (fetched === "FAILED" || tenantLevel >= TENANT_MAX_LEVEL) {
            return undefined;
        }
        return <LoadingIndicator small={true} />;
    }

    const viewTenants = (): boolean => {
        return !alertsViewVisibility;
    };

    return (
        <>
            <ApplicationLayout
                viewTitle={viewTenants() ? t("Common.customerTitle") : undefined}
                view={
                    viewTenants() ? (
                        <TenantsTable
                            count={state.count}
                            onTenantEdit={updateState}
                            onTenantDelete={updateState}
                            refreshCount={refreshingCount}
                            parentLevel={tenantLevel + 1}
                            setNewComponent={setAlertsViewVisibility}
                            tenantUuid={setTenantUuid}
                            tenantName={setTenantName}
                            rateVersions={rateVersions}
                        />
                    ) : (
                        <ManageLicenseAlertView
                            hideDialogView={setAlertsViewVisibility}
                            initialTenantData={undefined}
                            setEditView={() => {
                                ("");
                            }}
                            edit={false}
                            alertData={{
                                alertUuid: "",
                                alertName: "",
                                tenantName: "",
                                tenantUuid: "",
                                availableLicenses: [],
                                expirationDate: "",
                                poolName: "",
                                poolUuid: "",
                            }}
                            tenantsView={true}
                            tenantName={tenantName}
                            tenantUuid={tenantUuid}
                        />
                    )
                }
                mainRoute={TENANTS_ROUTE}
                viewActions={viewTenants() ? createViewActions() : undefined}
                onHandleRefresh={onRefreshing}
                enableRefresh={useFeature(FLAG_TENANT_DELETE) === true}
            />
            <ErrorModal
                hide={() => setErrorModalVisible(false)}
                visible={errorModalVisible}
                title={t("Common.error")}
                description={t("CustomersTable.failedToFetchLicenses")}
            />
        </>
    );
}
