import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import layoutStyle from "components/layout/layout.scss";
import { MCS_ROUTE } from "components/router/Routes";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";

export default function AllMcsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const handleCopyToken = () => {
        hybridMcService
            .createMcRegistrationToken()
            .then((registrationToken) => {
                return navigator.clipboard.writeText(registrationToken.token).then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.copyRegistrationCodeSuccess")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                });
            })
            .catch(() => console.error("Couldn't copy to clipboard"));
    };

    const viewActions = (
        <>
            <button className={buttonsStyle.primaryButtonWithoutIcon} onClick={handleCopyToken}>
                {t("Mcs.copyRegistrationCode")}
            </button>
        </>
    );

    return (
        <ApplicationLayout
            viewTitle={t("Mcs.title")}
            view={<div>MC rules!!!</div>}
            viewActions={viewActions}
            mainRoute={MCS_ROUTE}
        />
    );
}
