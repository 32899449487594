import { useFeature } from "flagged";
import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import menuStyle from "components/navigation/management-menu.scss";
import topNavigation from "components/navigation/top-navigation.scss";
import {
    LICENSES_ROUTE,
    MCS_ROUTE,
    RouteDefinition,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { getAllTenantsDetails, getCurrentTenantDetails, getTenantLayer } from "services/tenants/tenantCookieService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    checkValidity: (route: RouteDefinition) => boolean;
    isActiveLink: (route: RouteDefinition[]) => boolean;
}

const ManagementMenu = (props: Props) => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const childRoutes = [LICENSES_ROUTE, USERS_ROUTE, TENANTS_ROUTE, WORKFLOWS_ROUTE, MCS_ROUTE];
    const tenantDetails = getCurrentTenantDetails();
    const tenantLayer = getTenantLayer();
    const maxTenantLayer = 5;
    // Checking count of accessed tenant after user login and "getAlltenantDetails" will
    // return 1 even if no tenant is accessed, hence subtracting it by 1 for calculation
    const accessedTenantsCount = getAllTenantsDetails().length - 1;

    const getTooltip = (route: RouteDefinition, disabled: boolean): JSX.Element => {
        if (route.path === WORKFLOWS_ROUTE.path) {
            return !disabled ? (
                <div className={menuStyle.tooltip}>{t("Routes.workflowsMenu.paidWorkflowTooltip")}</div>
            ) : (
                <div className={menuStyle.tooltip}>
                    <div className={menuStyle.title}>{t("Routes.workflowsMenu.unpaidTitle")}</div>
                    <div className={menuStyle.content}>
                        {t("Routes.workflowsMenu.automateWorkflowsAndSimplifyCompliance")}
                    </div>
                    <div>{t("Routes.workflowsMenu.contactBlancco")}</div>
                </div>
            );
        }
        return <></>;
    };

    return (
        <Menu className={menuStyle.menu} data-testid={testIds.navigation.managementMenu.itself}>
            <ul>
                {childRoutes.map((childRoute) => {
                    let disabled = false;

                    if (!childRoute.isValidUser(user)) {
                        return false;
                    } else {
                        const flag = childRoute.getFeatureFlag();
                        if (flag != null && !useFeature(flag)) {
                            return false;
                        }
                        if (
                            tenantLayer + accessedTenantsCount == maxTenantLayer &&
                            tenantDetails.featureLicenses.includes("FEATURE_TENANT_MANAGEMENT")
                        ) {
                            tenantDetails.featureLicenses.splice(
                                tenantDetails.featureLicenses.indexOf("FEATURE_TENANT_MANAGEMENT"),
                                1
                            );
                        }
                        if (!childRoute.isAccessibleTo(tenantDetails)) {
                            if (childRoute.advertised) {
                                disabled = true;
                            } else {
                                return null;
                            }
                        }
                    }

                    return (
                        <li
                            key={childRoute.title}
                            className={props.isActiveLink([childRoute]) ? topNavigation.current : ""}
                        >
                            <MenuItem className={menuStyle.item} onKeyDown={undefined}>
                                {childRoute.path != WORKFLOWS_ROUTE.path ? (
                                    <NavLink
                                        activeClassName={topNavigation.current}
                                        className={""}
                                        exact={true}
                                        to={childRoute.path}
                                        data-testid={childRoute.testId}
                                    >
                                        {t(childRoute.title)}
                                    </NavLink>
                                ) : disabled ? (
                                    <div className={menuStyle.inActive} data-testid={childRoute.testId}>
                                        <div className={menuStyle.disableText}>{t(childRoute.title)}</div>
                                        <div>
                                            <Tooltip
                                                content={getTooltip(childRoute, disabled)}
                                                placement={"right-start"}
                                            >
                                                <div>
                                                    <PremiumFeatureIcon color={theme.iconFillColor} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : (
                                    <NavLink
                                        activeClassName={topNavigation.current}
                                        className={""}
                                        exact={true}
                                        to={childRoute.path}
                                        data-testid={childRoute.testId}
                                    >
                                        <div className={menuStyle.tooltipContainer}>
                                            <div className={menuStyle.menuItem}>{t(childRoute.title)}</div>
                                            <div>
                                                <Tooltip content={getTooltip(childRoute, disabled)} placement={"right"}>
                                                    <div className={menuStyle.statusSuccessful} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </NavLink>
                                )}
                            </MenuItem>
                        </li>
                    );
                })}
            </ul>
        </Menu>
    );
};

export default ManagementMenu;
