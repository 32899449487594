// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fAza89aOGR9CyW-2ON513{display:flex;flex-direction:row;align-items:center;padding:0;gap:12px}._1sqnrylt3jHyGJOPKnaCvU{margin-top:20px}._3VB48mqr0MAijPGE4kOcmy{margin:0}._3VB48mqr0MAijPGE4kOcmy h3{font-style:normal;font-weight:700;font-size:16px;line-height:24px;color:#303030;margin-top:15px}._3VB48mqr0MAijPGE4kOcmy p{font-style:normal;font-weight:500;font-size:12px;line-height:18px;color:#606060;margin-bottom:15px}._279mYseohdrTbFwWavWfnL{margin:0}._31lTU_9AKhACmqrstECGeT{margin:0}.j5RXBA-zlJijLABD5pGkA{display:flex;flex-wrap:wrap;justify-content:flex-start;gap:20px}.j5RXBA-zlJijLABD5pGkA>*{flex:0 0 445px;max-width:445px;height:116px;min-height:116px;max-height:116px}@media(max-width: 1439px){.j5RXBA-zlJijLABD5pGkA{flex-direction:column;align-items:center}}", ""]);
// Exports
exports.locals = {
	"planAndSupport": "fAza89aOGR9CyW-2ON513",
	"products": "_1sqnrylt3jHyGJOPKnaCvU",
	"assets": "_3VB48mqr0MAijPGE4kOcmy",
	"information": "_279mYseohdrTbFwWavWfnL",
	"additionalTools": "_31lTU_9AKhACmqrstECGeT",
	"productCardsWarapper": "j5RXBA-zlJijLABD5pGkA"
};
module.exports = exports;
